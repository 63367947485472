import {htmlLoading} from "./global";
import Handlebars from './../handlebars-v1.3.0.min'

// global functions
var stringify_tree_to_hidden_input = function (nestedArray) {
    var treeArray = {};
    for (var i = 0; i < nestedArray.length; i++) {
        if (!nestedArray[i].parent_id) nestedArray[i].parent_id = '';
        treeArray[nestedArray[i].item_id] = { parent_id: nestedArray[i].parent_id };
    }
    $('input[name=tree-array]').val(JSON.stringify(treeArray));
};

var create_fresh_category_tree = function (data) {
    var subtrees = $('.category-tree > ol.sortable').find('> li').clone();
    subtrees.find('.cat-ops, .edit-cat-name').remove()

    var prodtree = $('#products .category-tree');
    prodtree.children(':not(.all-products)').remove();
    prodtree.append(subtrees);
};

document.addEventListener('DOMContentLoaded', () => {
    //use app.user variables defined in shop/header.html.twig for blocking change in quantity when user has no permission
    const subuser_status = window.subuser_status == '1' ? true : false;
    const has_perm_add_to_cart = window.has_perm_add_to_cart == '1' ? true : false;
});


$(document)
    .ready(function () {
        // send selected filter parameters for route shops_orders
        $('.shops-orders-filter-parameter [type=hidden]').on('updated', function () {
            var tf_values = ['7d', '30d', '12m', 'l10'];
            var time_filter = $('[name=time_filter]').val();
            var tf = '';
            var tu = '';
            if (tf_values.indexOf(time_filter) == -1) {
                tf = $('[name=tf]').val();
                tu = $('[name=tu]').val();
            }
            $('.form-search-button').on('click', function () {
                tf = $('[name=tf]').val();
                tu = $('[name=tu]').val();
            });
            var restaurant_id = $('[name=restaurant_id]').val();
            var shop_id = $('[name=shop_id]').val();

            if (tf != '' && tu != '') {
                document.location.href = $('.shops-orders-filter-parameter').attr('data-url') + '?tf=' + tf + '&tu=' + tu + '&rf=' + restaurant_id + '&sf=' + shop_id;
            } else {
                document.location.href = $('.shops-orders-filter-parameter').attr('data-url') + '?tf=' + time_filter + '&rf=' + restaurant_id + '&sf=' + shop_id;
            }
        });

        // send selected filter parameters for route shop_orders
        $('.shop-orders-filter-parameter [type=hidden]').on('updated', function () {
            var tf_values = ['7d', '30d', '12m', 'l10'];
            var time_filter = $('[name=time_filter]').val();
            var tf = '';
            var tu = '';
            if (tf_values.indexOf(time_filter) == -1) {
                tf = $('[name=tf]').val();
                tu = $('[name=tu]').val();
            }
            $('.form-search-button').on('click', function () {
                tf = $('[name=tf]').val();
                tu = $('[name=tu]').val();
            });
            var restaurant_id = $('[name=restaurant_id]').val();

            if (tf != '' && tu != '') {
                document.location.href = $('.shops-orders-filter-parameter').attr('data-url') + '?tf=' + tf + '&tu=' + tu + '&rf=' + restaurant_id;
            } else {
                document.location.href = $('.shop-orders-filter-parameter').attr('data-url') + '?tf=' + time_filter + '&rf=' + restaurant_id;
            }
        });

        $('[data-value=select-dates]').on('click', function (e, data) {
            $('.dropdown-menu-select').removeClass('open');
            $('.dropdown-menu-select button').attr('aria-expanded', false);
            $('.orders').toggleClass('orders-time-filter-action');
            $('.wrong-date-range').toggleClass('hidden', true);
        });

        // shop's onloads
        if (!$('.shop-body').length) {
            return;
        }

        // after hogast login reload and clean up
        if ($('#my-all-shops-tabs').length) {
            var hogastReload = localStorage.getItem("hogast_reload");
            localStorage.removeItem("hogast_reload");

            if (hogastReload) {
                document.getElementById("hogast-shops-tab").classList.add("active");
                document.getElementById("hogast-shops").classList.add("active");
                document.getElementById("hogast-shops").classList.add("in");
            } else {
                document.getElementById("my-shop-tab").classList.add("active");
                document.getElementById("my-shops").classList.add("active");
                document.getElementById("my-shops").classList.add("in");
            }
        }

        //button toggle shops view from blocks to list
        $('#switch-shops-view-button-my-shops').on('click', function () {

            if ($(this).attr('data-state') === 'show-block') {
                $(this).attr('data-state', 'show-list');
                $(this).html(
                    '<svg width="1em" height="1em" viewbox="0 0 16 16" class="bi bi-list" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"></path></svg>'
                );
                /* $('#compact-shops-view').attr("style", "");
                $('#block-shops-view').attr("style", "display:none"); */

            } else if ($(this).attr('data-state') === 'show-list') {
                $(this).attr('data-state', 'show-block');
                $(this).html(
                    '<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-grid" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z"/></svg>'
                );

                /* $('#block-shops-view').attr("style", "");
                $('#compact-shops-view').attr("style", "display:none"); */
            }
        });

        $('#switch-shops-view-button-all-shops').on('click', function () {

            if ($(this).attr('data-state') === 'show-block') {
                $(this).attr('data-state', 'show-list');
                $(this).html(
                    '<svg width="1em" height="1em" viewbox="0 0 16 16" class="bi bi-list" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"></path></svg>'
                );
                /* $('#compact-shops-view').attr("style", "");
                $('#block-shops-view').attr("style", "display:none"); */

            } else if ($(this).attr('data-state') === 'show-list') {
                $(this).attr('data-state', 'show-block');
                $(this).html(
                    '<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-grid" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z"/></svg>'
                );

                /* $('#block-shops-view').attr("style", "");
                $('#compact-shops-view').attr("style", "display:none"); */
            }
        });

        $('#switch-shops-view-button').on('click', function () {

            if ($(this).attr('data-state') === 'show-block') {
                $(this).attr('data-state', 'show-list');
                $(this).html(
                    '<svg width="1em" height="1em" viewbox="0 0 16 16" class="bi bi-list" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"></path></svg>'
                );
                $('#compact-shops-view').attr("style", "");
                $('#block-shops-view').attr("style", "display:none");

            } else if ($(this).attr('data-state') === 'show-list') {
                $(this).attr('data-state', 'show-block');
                $(this).html(
                    '<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-grid" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z"/></svg>'
                );

                $('#block-shops-view').attr("style", "");
                $('#compact-shops-view').attr("style", "display:none");
            }
        });

        //disconnect external shop ajax
        $('body').on('click', '#disconnect-hogast-link', function () {
            $.ajax({
                url: $(this).attr('data-url'),
                type: 'post',
                success: function (response) {
                    $('#hogast-connected-icon-' + response.id).attr("style", "display:none");
                    $('#hogast-connect-modal').modal('hide');
                }
            });
        });

        //connect external shop ajax
        $('body').on('click', '#hogast-connect-button', function () {
            var shopId = $(this).attr('data-shop-id');
            $.ajax({
                url: $(this).attr('data-url'),
                type: 'post',
                data: { connect: $(this).attr('data-connect') },
                success: function (response) {
                    $('#hogast-connect-modal').modal('hide');
                    /* $('#shop-main-div-'+shopId).attr("style", "background-color: #ffffff"); */
                    $('#hogast-connected-icon-div-' + shopId).html(
                        '<a style="position: relative; float: right; margin-top: -2%; margin-right: 2%; padding-top: 9px; max-width: 100%;" class="async info-sign"><span class="info-sign-icon success" data-container="body" data-toggle="popover" data-placement="top" data-trigger="hover"><img style="max-width:100%;" src="/img/icon-connecting-to-supplier.svg"></span></a>'
                    );
                }
            });
        });

        // remember scroll state in product lists
        if (localStorage.getItem("scroll_state") != 0) {
            $(document).scrollTop(localStorage.getItem("scroll_state"));
        }
        $(window).on('scroll', function () {
            var scrollState = $(document).scrollTop();
            localStorage.setItem("scroll_state", scrollState);
        });

        // clean up if modal closed
        $('body').on('hidden.bs.modal', '#loading-modal', function () {
            $('.all-products .category-label').click();
            $('html').removeClass('async-loading');
            $('.private-shop .fileinput-button').removeClass('uploading').addClass('uploaded');
        });

        (function () {
            var bar = $('.private-shop .fileinput-button');

            $(document)
                .ajaxComplete(function (event, req) {
                    var json = req.responseJSON || null;
                    if (!json || !json.redirect) {
                        $('html').removeClass('async-loading');
                    }

                    if (json) {
                        if (json['loading_title']) {
                            $('#loading-modal-title').text(json['loading_title']);
                        }
                        if (json['loading_subtext']) {
                            $('#loading-modal-subtext').text(json['loading_subtext']);
                        }
                        if (json['loading_text']) {
                            $('#loading-modal-text').text(json['loading_text']);
                        }
                        if (json['continue_upload_url']) {
                            //bar.removeClass('uploading').addClass('uploaded');

                            if (loadingModalOpen()) {
                                $.ajax({
                                    url: json['continue_upload_url'],
                                    beforeSend: function () {
                                        $('html').addClass('async-loading');
                                        $(this).trigger('upload-start');
                                        bar.removeClass('uploading').addClass('uploaded');
                                    },
                                    success: function (data) {
                                        if (data) {
                                            if ('products_count' in data) {
                                                $('#products-count').text(data['products_count']);
                                                //bar.removeClass('uploading').addClass('uploaded');
                                            }
                                            if ('products_list' in data) {
                                                $('.products-list').html(data['products_list']);
                                            }
                                            if ('upload_error_message' in data) {
                                                $('.upload-error').show().find('.text-danger').html(data.upload_error_message);

                                            } else if ('finished' in data) {
                                                $('[href="#products-quick-add"]').tab('show');
                                                $('.products-list').trigger('reload-scroll');
                                            }
                                        }
                                    },
                                    error: function (data) {
                                        $('.loading-modal').modal('toggle');
                                    }
                                });
                            } else {
                                console.log('Import stopped...');
                            }
                        }
                    }
                });
        })();

        $('.shop-body [data-toggle="popover"]').popover();

        // shop chooser
        $('.available-shop').find('.shop-logo, .shop-name').click(function (e) {
            e.stopPropagation();
            e.preventDefault();
            e.stopImmediatePropagation();
            $(this).closest('.available-shop').find('.collapse').collapse('toggle');
        });
        $('.available-shop .collapse').on('show.bs.collapse', function () {
            var shop = $(this).closest('.available-shop');
            shop.siblings().find('.collapse').collapse('hide');
            shop.addClass('open');
        });
        $('.available-shop .collapse').on('hide.bs.collapse', function () {
            $(this).closest('.available-shop').removeClass('open');
        });
        $('.available-shop .customer-number').on('focus', function () {
            var $this = $(this);
            $this.closest('.available-shop').find('.collapse').collapse('show');
            return;

            if (!$this.data('popover-shown')) {
                setTimeout(function () {
                    $this.showPopover()
                    setTimeout(function () {
                        $this.hidePopover()
                    }, 4000);
                }, 1000);
                $this.data('popover-shown', true);
            }
        })
            .on('xblur xfocusout', function () {
                $(this).hidePopover();
            });
        // end: shop chooser

        // register for shop
        $('body').on('submit', '.shop-register-form', function () {
            var form = $(this);

            form.save(function (data) {
                if ('shop_status' in data) {
                    form.fadeOut(function () {
                        form.siblings('.user-shop-status-waiting, .unregister').fadeIn(function () {
                        }).find('number').text(data.customer_number);
                        form.siblings('.shop-become-customer').fadeOut()
                    });
                }
            });
            return false;
        });
        $('body').on('upload-success', '.fileupload', function (e, data) {
            if ('file' in data) {
                $('#privacy-policy-file').text(data.file);
            }
        });

        // unregistered
        $('body').on('async-success', '.user-shop-status .unregister', function (e, data) {
            $(this).closest('.modal').modal('hide');

            if (data && 'id' in data) {
                $('[data-shop-id="' + data.id + '"]').find('.info-sign.info-sign-waiting').remove();
            }
        });

        // auto load shops carts
        $('.shops-cart-content[data-url]').each(function () {
            var $this = $(this);

            $.ajax({
                url: $this.attr('data-url'),
                type: 'post',
                success: function (data) {
                    $this.removeClass('loading');
                    if ('empty' in data) {
                        $this.closest('.shops-cart').addClass('cart-empty');
                        $this.collapse('hide');
                    } else
                        if ('html' in data) {
                            $this.html(data.html).collapse('show');
                        }
                }
            });
        });
        // click on name in shops cart header toggles the cart content
        $('.shops-cart-header .shop-name').find('i, .name').click(function () {
            $(this).closest('.shops-cart').find('.shops-cart-content').collapse('toggle');
        });

        $('.private-shop-categories-submit button').click(function () {
            $('a[href="#products"]').click();
        });

        // toggle shop switcher
        $('.switch-shop').click(function () {
            var $this = $(this).toggleClass('sichtbar');

            if ($this.hasClass('sichtbar')) {
                $('.page-overlay').show();

                // close on ESCape
                $('body').on('keypress keyup keydown', function (e) {
                    if (e.which == 27) {
                        $('.switch-shop').click();
                    }
                });
            } else {
                $('.page-overlay').hide();
                $('body').off('keypress keyup keydown');
            }
        });

        // update cart
        var update_cart_product = function (product, data) {
            var shop = $('[data-shop-id=' + data.shop + '], .cart-form');

            if (product) {
                shop.add(product.closest('.cart-content'));
            }

            var cart_total = shop.find('.shop-cart-total');
            var cart_products_number = shop.find('.shop-cart-products-number');

            if (!cart_products_number.find('number').text(data.products_number).length) {
                cart_products_number.text(data.products_number);
            }

            var top_menu_cart_products_number = $('.top-menu .shop-cart-products-number');
            if (data.products_number > 0 &&
                parseInt(top_menu_cart_products_number.text()) != parseInt(data.products_number)) {
                top_menu_cart_products_number.addClass('bounce');
                setTimeout(function () {
                    top_menu_cart_products_number.removeClass('bounce');
                }, 1500)
            }
            top_menu_cart_products_number.text(data.products_number ? data.products_number : '');

            cart_total.find('sum').text(localizedNumber(data.total));

            if (data.weight) {
                $('#weight-per-single-article weight').text(data.weight);
            }

            var cart_short = $('.cart-short[data-shop-id=' + data.shop + ']');
            if (cart_short.length) {
                var last_product = cart_short.find('.product[data-id="' + data.id + '"]');
                if (last_product.length) {
                    if (data.product_number == 0) {
                        last_product.slideUp(function () {
                            last_product.remove()
                        });
                    }
                }

                setTimeout(function () {
                    if (data.products_number == 0) {
                        cart_short.addClass('cart-empty');
                    } else {
                        cart_short.removeClass('cart-empty');
                    }
                }, 500);

                if ('last_products' in data) {
                    const itemQuantity = document.getElementById('NEW_ITEM-QUANTITY-' + (data.products_number));
                    if (itemQuantity !== null) {
                        itemQuantity.value = data.last_products[0].number;
                    }
                    var cart_short_products = cart_short.find('.cart-short-products');
                    var total_summary = cart_short.find('.shop-cart-total');
                    var append = (data.last_products.length == 1);

                    $.each(data.last_products, function (i, d) {
                        if (d.number <= 0) {
                            return
                        }

                        var last_product = cart_short.find('.product[data-id="' + d.id + '"]');
                        if (!last_product.length) {
                            var tmpl = cart_short.find('.product.product-tmpl');
                            last_product = tmpl.clone()
                                .hide()
                                .removeClass('hidden product-tmpl')
                                .attr('href', d.url)
                                .attr('data-id', d.id)
                            last_product.find('.name name').text(d.name);
                            last_product.find('.name small').text(d.sdesc);

                            cart_short_products.prepend(last_product);
                            last_product.slideDown(function () {
                                last_product.blinkMe();
                            });
                        }

                        last_product.find('.summary number').text(d.number);
                        last_product.find('.summary sum').text(d.total);
                    });
                }
            }


            shop.toggleClass('under-min-sum', data.under_min_sum);
            //shop.find('[type=submit]').prop('disabled', data.under_min_sum);

            if (data.vat) {
                shop.find('.shop-cart-vat sum').text(localizedNumber(data.vat));
            }
            if (data.total_with_vat) {
                shop.find('.shop-cart-total-all sum').text(localizedNumber(data.total_with_vat));
            }

            if (data.beer_vat) {
                shop.find('.shop-cart-beervat sum').text(localizedNumber(data.beer_vat));
            }

            if (product) {
                product.find('.current-price .current-product-price').text(data.price_unit);
                product.find('.total').text(localizedNumber(data.product_total));
                var quantity = product.find('.quantity');
                if (quantity.prop('tagName') == 'INPUT') {
                    quantity.val(localizedNumber(data.product_number));
                    //quantity.data('old_quantity', data.product_number);
                } else {
                    quantity.text(localizedNumber(data.product_number));
                }

                product.toggleClass('in-cart', (data.product_number > 0));

                $('.ingredient-add-to-cart[data-product-id="' + data.id + '"] a')
                    .toggleClass('cart-product', (data.product_number > 0))
                    .find('.cart-quantity').text(localizedNumber(data.product_number));
            }
            return false;
        };
        $('body').on('click', '.cart-link', function (e) {
            var a = $(this);
            if (!a.parents('.buyable').length) {
                e.preventDefault();
                console.log(1);
                return false;
            }
            a.async();
            return false;
        });

        // plus/minus without automatics
        $('bodyx').on('click', '.modal .plus-minus a.no-update, .productdetails .plus-minus a.no-update', function () {
            var $this = $(this), minus = $this.hasClass('minus'),
                q = $this.closest('.plus-minus').find('input[name=quantity]');

            var quantity = toNumber(q.val());
            if (minus) {
                quantity--;
                if (quantity <= 0) {
                    quantity = 0;
                }
            } else {
                quantity++;
            }

            q.val(localizedNumber(quantity));
            return false;
        });
        // form update cart
        $('body').on('click', 'form.update-cart-product [name=submit-go]', function () {
            $(this).form().append('<input type="hidden" name="redirect" value="1" />').submit();
            return false;
        });
        $('body').on('submit', 'form.update-cart-product', function () {
            var $this = $(this);
            $('html').addClass('async-loading');

            $this.save(function (data) {
                $this.find('input[name=redirect]').remove();
                var product = $this.closest('.product');
                update_cart_product(product.length ? product : $this.find('.product'), data);
            });
            return false;
        });

        // menu
        var menu = $('.shop-body .main-menu');
        // load categories on page load
        var loadCategories = function (e) {
            var $this = $(this), url = $this.attr('data-load-url');

            $.ajax({
                url: url,
                beforeSend: function () {
                    if (e.type != 'reload-categories') {
                        $this.addClass('loading');
                    }
                },
                success: function (data) {
                    $this.html(data);
                    $this.addClass('loaded');

                    // open subsub categories on load
                    $this.find('.cat.active').each(function () {
                        var $this = $(this);
                        $this.parents('ul.cat').collapse('toggle').siblings().collapse('toggle');
                        $this.closest('.first-level').siblings('.first-level').addBack().slideDown();
                    });
                },
                complete: function () {
                    $this.removeClass('loading');
                }
            });
        };
        menu.on('load-categories', '.categories[data-load-url]:not(.loaded):not(.loading)', loadCategories);
        menu.on('reload-categories', '.categories[data-load-url]', loadCategories);
        menu.find('.categories[data-load-url]:not([data-load-url-auto=false])').trigger('load-categories');

        // open submenu
        var toggle_cats = menu.find('li .toggle-categories').on('click toggle-categories', function (e) {
            var li = $(this).closest('li'),
                cats = $(this).closest('li').find('> ul.categories');

            if ($(e.target).is('[data-href]')) {
                $(e.target).trigger('fake-click');
                return;
            }

            if (cats.is(':visible')) {
                cats.slideUp();
                li.removeClass('slide-down');
            } else {
                cats.trigger('load-categories');

                // close other open categories
                li.siblings().find('> ul.categories').slideUp();
                cats.slideDown(function () {
                    $('#left-menu-items').animate({
                        scrollTop: 0
                    }, 0)
                });
                li.addClass('slide-down');
            }
            return false;
        });
        toggle_cats.filter('.open-on-start').trigger('toggle-categories');

        if (RegExp('catId').test(document.location.href)) {
            if (RegExp('favorites', 'i').test(document.location.href)) {
                $('#favorites-categories:not(.open-on-start)').trigger('toggle-categories');
            } else {
                $('#main-categories:not(.open-on-start)').trigger('toggle-categories');
            }
        }

        // catch open/close
        menu.on('show.bs.collapse', 'ul.cat', function () {
            $(this).siblings('a').addClass('collapsed');

        }).on('hide.bs.collapse', 'ul.cat', function () {
            var $this = $(this), li = $this.closest('li');
            $this.find('li > a.collapsed').removeClass('collapsed');

            if (li.hasClass('first-level')) {
                var a = li.find('> a.collapsed');
                if (a.length && li.find('> ul.collapsing').length) {
                    a.removeClass('collapsed')
                }
            }
        });
        // open subcategories on load
        $('.nav > .cat.active').each(function () {
            $(this).find('> ul.cat').collapse('toggle');
        });

        // carousel in tabs? switch to next tab if last item
        $('.dashboard .tab-pane .carousel').on('slid.bs.carousel', function (e) {
            var $this = $(e.relatedTarget), carousel = $this.closest('.carousel');
            if (carousel.is(':visible') && $this.is(':last-child')) {
                var tab = $this.closest('.tab-pane[id]'), nextTab = tab.next('.tab-pane');
                if (!nextTab.length) {
                    nextTab = tab.siblings('.tab-pane[id]').first();
                }
                var id = nextTab.attr('id');
                var lnk = $('[href=#' + id + '][data-toggle=tab]');
                if (lnk.length) {
                    setTimeout(function () {
                        nextTab.find('.carousel').carousel(0);
                        setTimeout(function () {
                            lnk.tab('show');
                        }, 1300);
                    }, 3700);
                }
            }

        });


        // switch view
        $('.shop-body .switch-view').click(function () {
            var $this = $(this);
            $this.toggleClass('compact');
            var products = $('.products-table, .products').first().toggleClass('compact');
            var shop_compact_view = $this.hasClass('compact');

            if (!shop_compact_view) {
                products.find('img[data-src]').each(function () {
                    var $this = $(this);
                    $this.attr('src', $this.attr('data-src')).removeAttr('data-src');
                });
            }

            var url = $this.attr('data-url');
            if (url) {
                $.ajax({
                    url: url,
                    data: {
                        k: 'shop_compact_view',
                        v: shop_compact_view ? 1 : 0
                    }
                });
            }
        });

        // close others
        menu.on('close-other-parents', function (e, $this, ignore) {
            var cats = $this.parents('li.cat').last().siblings('.cat');

            if (ignore) {
                ignore = $(ignore).get(0);

                cats.each(function () {
                    var $this = $(this);
                    if (!$.contains($this.get(0), ignore)) {
                        $this.children('ul').collapse('hide')
                    }
                });
            } else {
                cats.find('> a.collapsed').removeClass('collapsed');
                cats.children('ul').collapse('hide')
            }
        });
        menu.find('li ul').collapse({ toggle: false });
        menu.on('click', '.collapse-menu', function () {
            var $this = $(this), ul = $this.closest('li').children('ul');
            if (!ul.hasClass('in')) {
                menu.trigger('close-other-parents', [ul, menu.find('.cat.active:not(.all-products)')]);
                $this.addClass();
            }
            ul.collapse('toggle');
            return false;
        });
        // open subsub categories on load
        menu.find('.cat.active').each(function () {
            var $this = $(this);
            $this.parents('ul.cat').collapse('toggle').siblings().collapse('toggle');
            $this.closest('.first-level').siblings('.first-level').addBack().slideDown();
        });


        // blocks
        (function () {
            $('body').on('data-scrolled-out', '#productBlocks', function () {
                var wait = 0;
                $('#productBlocks').find('.productBlock.preload').each(function () {
                    var $this = $(this);
                    setTimeout(function () {
                        $this.removeClass('preload');
                    }, wait * 1000);
                    //wait += .08;
                });
            }).trigger('data-scrolled-out');
            $(window).on("popstate", function (e) {
                if (e.originalEvent.state !== null) {
                    var url = window.location.pathname + window.location.search;
                    menu.find('.cat a[href="' + url + '"]').trigger('click', [true]);
                }
            });
            menu.on('click', '.cat a .edit-item', function (e) {
                e.stopPropagation();
                document.location.href = $(this).attr('data-href');
                return false;
            });
            menu.on('click', '.cat a', function (e, no_history) {
                var $this = $(this), url = $this.attr('href');

                if ($('.content.products.cart, .content .orders-list, .content .order-products-form').length) {
                    document.location.href = url;
                    return true;
                }


                $('#left-menu .navbar-toggle.offcanvas').click();
                $this.find('+ ul.collapse').collapse('show');

                if ($('.cart.content, .order.content, .orders.content, .productdetails.content').length > 0 || $('.content .products').length == 0) {
                    document.location.href = url;
                    return false;
                }

                var data = {};
                if (!$('#productBlocks').length) {
                    data.productBlocks = 1;
                }



                $.ajax({
                    url: url,
                    beforeSend: function () {
                        $('html').addClass('async-loading');
                    },
                    data: data,
                    success: function (data) {
                        $('#product-filter').toggle(RegExp('favorites', 'i').test(url));

                        var blocks = $('#productBlocks');
                        if (!blocks.length || data.productListResort) {
                            $('.content').html(data.html);
                            blocks = $('#productBlocks');
                            shopProductListResort();
                        } else {
                            blocks.html(data.html);
                        }
                        blocks.trigger('data-scrolled-out');

                        if (!no_history) {
                            history.pushState({}, '', url);
                        } else {
                            $this.parents('ul.cat').collapse('show');
                        }
                        if (data.url) {
                            blocks.removeClass("scroll-loaded").attr("data-scroll-load-url", data.url);
                        } else {
                            blocks.addClass('scroll-loaded').removeAttr('data-scroll-load-url');
                        }
                        setTimeout(function () {
                            init_scroll_load();
                        }, 300);

                        var no_close = $.contains($this.parents('li.cat').last().get(0), menu.find('li.active:not(.all-products)').last().get(0));
                        menu.find('li.active').removeClass('active');
                        var li = $this.closest('li').addClass('active');
                        li.parents('li').addClass('active');
                        li.children('ul').collapse('show');

                        if (!no_close) {
                            menu.trigger('close-other-parents', [$this]);
                        }

                        // scroll to top
                        $("html, body").animate({ scrollTop: 0 });
                    }
                });
                return false;
            });
        })();
        // add to cart
        $('body').on('click', '.product .add-product', function () {
            var $this = $(this);
            $this.closest('.product').find('[name=quantity]').val(1).trigger('change');
            return false;
        });
        // ignore clicks in plus-minus area
        $('body').on('focus click', '.product .update-product', function () {
            return false;
        });
        $('body').on('focus click', '.product .quantity, .update-cart-product .quantity', function () {
            var $this = $(this);
            setTimeout(function () {
                $this.select();
            }, 100);
            return false;
        });
        var disable_plus_minus = function (product) {
            product.find('.plus, .minus, .plus-minus').prop('disabled', true).addClass('disabled');
            product.closest('.add-to-cart').addClass('disabled');
        };
        var enable_plus_minus = function (product) {
            product.find('.plus, .minus, .plus-minus').prop('disabled', false).removeClass('disabled');
            product.closest('.add-to-cart').removeClass('disabled');
        };

        // plus minus
        $('body.shop-body').on('click', '.plus-minus a:not(.no-update)', function () {
            var form = document.querySelector('.update-cart-product');
            if(form){
                var actionUrl = form.action;
                var queryString = 'shouldUpdate=true';

                if (!actionUrl.includes(queryString)) {
                    actionUrl += (actionUrl.includes('?') ? '&' : '?') + queryString;
                }

                form.action = actionUrl;
            }
            var $this = $(this), product = $this.closest('.product'), data = {};
            var plus = $this.hasClass('plus');
            var q = product.find('input.quantity');

            var quantity = toNumber(q.val());
            if (!plus) {
                quantity--;
                if (quantity <= 0) {
                    quantity = 0;
                }
            } else {
                quantity++;
            }

            if (subuser_status) {
                if (!has_perm_add_to_cart) {
                    //user has no permission, don't change quantity value (further block of action is in shop controller)
                    quantity = toNumber(q.val());
                }
            }

            q.val(localizedNumber(quantity));

            var timeout_ms = (!product.hasClass('in-cart') || q == 0) ? 0 : 600;

            clearTimeout(product.data('updateTimeout'));
            product.data('updateTimeout', setTimeout(function () {
                var form = $this.closest('form.update-cart-product');
                if (form.length) {
                    disable_plus_minus(product);
                    form.save(function (data) {
                        update_cart_product(product, data);
                    }, function () {
                        enable_plus_minus(product);
                    });
                } else {
                    q.trigger('change');
                }
            }, timeout_ms));
            return false;
        });
        // update on quantity change
        $('body').on('change', 'input[name=quantity][data-update-url]', function (e) {
            var form = document.querySelector('.update-cart-product');
            if(form){
                var actionUrl = form.action;
                var queryString = 'shouldUpdate=true';

                if (!actionUrl.includes(queryString)) {
                    actionUrl += (actionUrl.includes('?') ? '&' : '?') + queryString;
                }
                form.action = actionUrl;
            }
            var $this = $(this), quantity = $this.val(),
                product = $this.closest('.product');
            var form = $this.closest('form.update-cart-product');
            if (form.length) {
                disable_plus_minus(product);
                form.save(function (data) {
                    update_cart_product(product, data);
                }, function () {
                    enable_plus_minus(product);
                });
            } else {
                $.ajax({
                    url: $this.attr('data-update-url'),
                    type: 'post',
                    data: {
                        quantity: quantity,
                        packaging: product.find('[name=packaging]').val()
                    },
                    beforeSend: function () {
                        $('html').addClass('async-loading');
                        disable_plus_minus(product);
                    },
                    success: function (data) {
                        update_cart_product(product, data)
                    },
                    complete: function () {
                        enable_plus_minus(product);
                    }
                });
            }
            $this.blur();
            // enter in quantity input
        }).on('keydown keypress', '[name=quantity][data-update-url]', function (e) {
            if (e.which == 13) {    // enter
                e.preventDefault();
                e.stopPropagation();
                $(this).blur();
                return false;
            }
            return true;
        });
        // product list: remove product from cart
        $('body.shop-body').on('click', '.products .product .close, .remove-from-cart', function () {
            var $this = $(this);
            $this.async(function (data) {
                update_cart_product($this.closest('.product'), data);
            });
            return false;
        });
        // incart: remove product from cart
        $('body').on('click', '.cart .product .close, .cart-product .close', function () {
            var $this = $(this);
            $this.async(function (data) {
                var product = $this.closest('.product');
                product.find('.quantity').val('0');
                if (product.hasClass('not-available')) {
                    product.fadeOut(function () {
                        product.remove()
                    });
                }
                update_cart_product(null, data);
            });
            return false;
        });
        // update on comment change
        $('body').on('focusout', '[name=comment][data-update-url]', function (e) {
            var $this = $(this);
            $.ajax({
                url: $this.attr('data-update-url'),
                type: 'post',
                data: { comment: $this.val() },
                beforeSend: function () {
                    $('html').addClass('async-loading');
                    $this.addClass('saving');
                },
                success: function () {
                    $this.removeClass('saving');
                }
            });
            return false;
        });
        // toggle comment
        $('.shop-body .collapse').on('shown.bs.collapse', function () {
            $(this).find('textarea').focus().select();
            return false;
        });
        // delivery location selector
        $('.cart-restaurant-selector li a').on('click fake-click', function () {
            $(this).async();
            return false;
        });
        // delivery date selector
        $('.cart .delivery-date-selector li a').on('click fake-click', function () {
            $(this).async();
            //return false;
        });
        // packaging unit changed?
        $('.shop-body input[name=unit][type=hidden]').on('updated', function () {
            var form = document.querySelector('.update-cart-product');
            if (form){
                var actionUrl = form.action;
                var queryString = 'shouldUpdate=true';

                if (actionUrl.includes('?' + queryString)) {
                    actionUrl = actionUrl.replace('?' + queryString, '');
                } else if (actionUrl.includes('&' + queryString)) {
                    actionUrl = actionUrl.replace('&' + queryString, '');
                }
                form.action = actionUrl;
            }
            var $this = $(this);
            $this.closest('.product').toggleClass('total-unknown', ($this.val() != $this.attr('data-default-unit')));
            $this.closest('form').submit();
        });
        // packaging unit changed?
        $('.shop-body input[name=packaging][type=hidden]').on('updated', function () {
            var form = document.querySelector('.update-cart-product');
            if (form){
                var actionUrl = form.action;
                var queryString = 'shouldUpdate=true';

                if (actionUrl.includes('?' + queryString)) {
                    actionUrl = actionUrl.replace('?' + queryString, '');
                } else if (actionUrl.includes('&' + queryString)) {
                    actionUrl = actionUrl.replace('&' + queryString, '');
                }

                form.action = actionUrl;
            }
            $(this).closest('form').submit();
        });
        // toggle selected
        $('.product .product-select input[type=checkbox]').on('change', function () {
            var $this = $(this), form = $this.closest('form');
            form.find('[type=submit]').prop('disabled', (form.find('.product-select input:checked').length == 0))
        });
        // calender select
        $('#orders-date-period [data-toggle=div]').on('click', function (e, data) {
            $(this).trigger('clicked')
        });
        // closing dropdown list after choosing custom period as a time filter
        $('#orders-date-period [data-value=select-dates]').on('click', function (e, data) {
            $('.dropdown-menu-select').removeClass('open');
            $('.dropdown-menu-select button').attr('aria-expanded', false);
            $('.orders').toggleClass('orders-time-filter-action');
        });
        // delivery note details
        $('#deliveryDetails').on('change', function () {
            var $this = $(this), checked = $this.is(':checked');
            $('.order-products').toggleClass('delivery-note-details', checked);
        });

        if (!$('.products:not(.panel-body), .products-table').length) {
            $('.navbar-fixed-top .navbar-header .switch-view').hide().remove('.visible-xs')
        }

        // filters
        (function () {
            var product_filters = $('#product-filter');
            product_filters.find('input')
                .on('set-active', function (e) {
                    $(this).prop('checked', true).closest('label').addClass('active');
                })
                .on('change', function (e) {
                    var data = {};
                    var one_checked = false;

                    product_filters.find('input').each(function () {
                        var $this = $(this), on = $this.prop('checked');
                        data[$this.attr('name')] = on ? 1 : 0;

                        if (on) {
                            one_checked = true;
                        }
                    });

                    if (!one_checked) {
                        e.stopPropagation();
                        e.stopImmediatePropagation();
                        $(this).trigger('set-active');
                        return false;
                    }


                    if (!$('#productBlocks').length) {
                        data.productBlocks = 1;
                    }


                    clearTimeout(product_filters.data('clicked-timeout'));
                    product_filters.data('clicked-timeout', setTimeout(function () {
                        $.ajax({
                            url: document.location.href,
                            data: data,
                            beforeSend: function () {
                                htmlLoading();
                            },
                            success: function (data) {
                                var blocks = $('#productBlocks');
                                if (!blocks.length) {
                                    $('.content').html(data.html);
                                    blocks = $('#productBlocks');
                                } else {
                                    blocks.html(data.html);
                                }

                                blocks.trigger('data-scrolled-out');

                                if (data.url) {
                                    blocks.removeClass("scroll-loaded").attr("data-scroll-load-url", data.url);
                                } else {
                                    blocks.addClass('scroll-loaded').removeAttr('data-scroll-load-url');
                                }
                                setTimeout(function () {
                                    init_scroll_load();
                                }, 300);

                                $('#favorites-categories + .categories').trigger('reload-categories');
                            },
                            complete: function () {
                                htmlLoading(false);
                            },
                        });
                    }, 100));
                });
        })();

        // category selector
        if ($('#product-category-selector').length) {
            $('#product-category-selector li a[data-value]').on('click fake-click', function (e, d) {
                var a = $(this), menu = a.closest('.dropdown-menu-select'),
                    id = a.attr('data-value'), li = a.closest('li');

                if (!id.match(/^(\d+|no-category)$/)) {
                    if (id == 'show-all-categories' && !li.hasClass('checked')) {
                        li.addClass('checked');
                        removeStyle(menu.data('style'));
                        menu.removeData('style');

                        var cats = li.siblings('.cat').removeClass('checked');
                        cats.each(function () {
                            var $this = $(this), style = $this.data('style');
                            if (style) {
                                removeStyle(style);
                            }
                        });

                        if (!d) {
                            setTimeout(function () {
                                menu.removeClass('open');
                                $(window).triggerHandler('scroll')
                            }, 250);
                        }
                        $(window).triggerHandler('scroll')
                        return false;
                    }
                    $(window).triggerHandler('scroll')
                    return;
                }

                li.toggleClass('checked');
                li.siblings('li:has([data-value=show-all-categories])').removeClass('checked');

                var active = li.hasClass('checked');
                var style = '.shop-middle [data-cat-id="' + id + '"] { display: block; } .shop-middle .products-table [data-cat-id="' + id + '"] { display: table-row; }';

                if (active) {
                    if (!menu.data('style')) {
                        menu.data('style', setStyle('.shop-middle .product, .shop-middle .product-category, .shop-middle [data-cat-id] { display: none; }'));
                    }
                    li.data('style', setStyle(style));
                } else {
                    removeStyle(li.data('style'));

                    if (!li.siblings('.checked').length) {
                        li.siblings('li').find('a[data-value=show-all-categories]').trigger('click', [true]);
                    }
                }
                $(window).triggerHandler('scroll')
                return false;
            });

            // init on start
            $('#product-category-selector li a[data-value=show-all-categories]').trigger('fake-click');

            // hide non existings cats from the selector
            var products = $('.products, .products-table');
            if (products.length) {
                products.on('hide.not.available.cats', function () {
                    var visible = 0, no_category_visible = false;
                    $('#product-category-selector .cat a[data-value]').each(function () {
                        var a = $(this), id = a.attr('data-value'), li = a.closest('li');

                        if (!products.find('[data-cat-id="' + id + '"]:first').length) {
                            li.hide();
                        } else {
                            visible++;
                            li.show();
                            if (id == 'no-category') {
                                no_category_visible = true;
                            }
                        }
                    });
                    var no_category_divider = $('#product-category-selector').find('.divider.no-category');
                    if (visible > 1 && no_category_visible) {
                        no_category_divider.show();
                    } else {
                        no_category_divider.hide();
                    }
                })
                    .trigger('hide.not.available.cats')
                    .on('loaded.part loaded.full', function () {
                        products.trigger('hide.not.available.cats')
                    });
            }
        }

        // show form to change customer number before sending it
        $('.user-shop-toggle-customer-number').click(function () {
            var $this = $(this);
            $this.closest('.customer-number-predefined').slideUp().siblings('.customer-number-unknown').slideDown();
            return false;
        });

        init_scroll_load(true);


        // PRIVATE SHOPS
        // ps: logo upload
        $('.uploadlogo').on('click', function (e) {
            var input = $(this),
                url = input.attr('data-url'),
                photos_list = $(input.attr('data-photos-container')),
                limit = input.attr('data-limit'),
                limit_remove_first = input.attr('data-limit-remove-first'),
                prepend_photo = input.attr('data-prepend-photo') == 'true',
                connect_with = input.attr('data-connect-with');

            if (!input.hasClass('fileupload-added')) {
                input.fileupload({
                    url: input.attr('data-url'),
                    dataType: 'json',
                    sequentialUploads: false,
                    limitConcurrentUploads: 3,
                    done: function (e, data) {
                        if (data.result.imgs) {
                            $.each(data.result.imgs, function (index, imgdata) {
                                if (data.context) {
                                    var img = data.context.img.find('img')
                                        .attr('src', imgdata.thumb)
                                        .attr('data-id', imgdata.id)
                                        .attr('data-url', imgdata.url);

                                    img.siblings('[type=hidden].file').val(imgdata.thumb);

                                    // remove url
                                    if ('remove_url' in imgdata) {
                                        data.context.img.find('.btn-remove').attr('data-href', imgdata.remove_url)
                                    }

                                    data.context.img.removeClass('uploading');

                                    // inform connect_with
                                    if (connect_with) {
                                        $(connect_with).trigger('image-added', [data.context.img]);
                                    }

                                    if (limit) {
                                        var photos = photos_list.find('img');
                                        if (photos.length > limit && limit_remove_first) {
                                            if (prepend_photo) {
                                                photos = $(photos.get().reverse());
                                            }
                                            photos.each(function (i) {
                                                if (i < limit) {
                                                    $(this).parents('.photo').first().remove();
                                                }
                                            });
                                        }
                                    }
                                }
                            });
                        }
                    },
                    submit: function () {
                        return true
                    },
                    progress: function (e, data) {
                        if (data.context) {
                            var progress = parseInt(data.loaded / data.total * 100, 10) + '%';
                            data.context.bar.css('width', progress).text(progress);
                        }
                    },
                    add: function (e, data) {
                        // check limit to disable upload
                        if (limit && !limit_remove_first) {
                            var photos = photos_list.find('.photo');
                            if (photos.length >= limit) {
                                photos.trigger('limit-reached');
                                return false;
                            }
                        }

                        var temp_name = '';
                        if (data.files && data.files.length > 0 && data.files[0].name) {
                            temp_name = data.files[0].name;
                        }

                        var newimg = $(Handlebars.compile(photos_list.find('script.tmpl').html())({ uploading: 'uploading', temp_name: temp_name }));
                        var newimg_progress_bar = newimg.find('.progress-bar');
                        if (prepend_photo) {
                            photos_list.prepend(newimg);
                        } else {
                            photos_list.append(newimg);
                        }

                        // check again to show msg rightaway
                        if (limit && !limit_remove_first) {
                            photos = photos_list.find('.photo');
                            if (photos.length >= limit) {
                                photos.trigger('limit-reached');
                            }
                        }

                        data.context = {
                            img: newimg.first(),
                            bar: newimg_progress_bar.first()
                        };

                        photos_list.removeClass('hidden');
                        photos_list.slideDown();
                        data.submit();
                    },
                    fail: function (e, data) {
                        if (data.context) {
                            data.context.img.remove();
                            //notification(translations.unknown_upload_error);
                            //notification('upload error')
                            var photos = photos_list.find('.photo');
                            if (!photos.length) {
                                photos.addClass('hidden');
                            } else {
                                photos.removeClass('hidden');
                            }
                        }
                    },
                    always: function (e, data) {
                        // check again to fix it
                        setTimeout(function () {
                            var photos = photos_list.find('.photo');
                            if (limit && !limit_remove_first) {
                                if (photos.length >= limit) {
                                    photos.trigger('limit-reached');
                                } else {
                                    photos.trigger('limit-unreached');
                                }
                            }
                        }, 100);
                    }
                }).prop('disabled', !$.support.fileInput)
                    .addClass('fileupload-added')
                    .parent().addClass($.support.fileInput ? undefined : 'disabled')
            }
        });

        $('#dashboard').find('.general-settings-group input:checkbox').on('click', function () {
            var cb = $(this),
                url = cb.attr('data-toggle');

            $.ajax({
                url: url,
                beforeSend: function () {
                    $('html').addClass('async-loading');
                },
                success: function (data) {
                    cb.prop('checked', data.status);
                    if (data.status) {
                        cb.closest('.input-group').addClass('active-ordering')
                    } else {
                        cb.closest('.input-group').removeClass('active-ordering')
                    }
                }
            });
            return false;
        });

        $('.dropdown-menu.shop-color a').on('click', function () {
            var colorCode = $(this).attr('data-value');
            $('#shop-color').val(colorCode);
        });

        $('.dropdown-menu.supplier.country a').on('click', function () {
            var countryCode = $(this).attr('data-value');
            $('input[name="supplier[country]"]').val(countryCode);
        });

        $('.dropdown-menu.shipment.country a').on('click', function () {
            var countryCode = $(this).attr('data-value');
            $('input[name="shipment[country]"]').val(countryCode);
        });

        $('.time-dropdown .dropdown-menu a').on('click', function () {
            var time = $(this).attr('data-value'),
                input = $(this).closest('.time-dropdown').find('input:hidden');

            input.val(time);
        });

        $('.weekday-dropdown .dropdown-menu a').on('click', function () {
            var weekday = $(this).attr('data-value'),
                input = $(this).closest('.weekday-dropdown').find('input:hidden');

            input.val(weekday);
        });

        $('#basic-info').find('button:submit').on('click', function () {
            var form = $(this).closest('form');

            form.save(function (data) {
                if ('logo' in data) {
                    var logo = $('.shop-logo img');
                    if (logo.attr('src') != data.logo) {
                        logo.fadeOut(function () {
                            logo.attr('src', data.logo).fadeIn()
                        })
                    }
                }
                if ('color' in data) {
                    $('.private-shop-header-wrapper > .header').css('background-color', '#' + data.color);
                }
                if ('textColor' in data) {
                    $('.private-shop-header-wrapper > .header').css('color', '#' + data.textColor);
                }
                if ('name' in data) {
                    $('.private-shop-header-wrapper .name').text(data.name);
                }
                if ('descr' in data) {
                    $('.private-shop-header-wrapper .descr').text(data.descr);
                }

                $('a[href$="#supplier"]').trigger('click');
            });
            return false;
        });

        $('#supplier').find('button:submit').on('click', function () {
            var form = $(this).closest('form');
            form.save(function () {
                $('a[href$="#my-data"]').trigger('click');
            });
            return false;
        });

        $('#my-data').find('button:submit').on('click', function () {
            var form = $(this).closest('form');
            form.save(function () {
                $('a[href$="#orders"]').trigger('click');
            });
            return false;
        });

        $('#orders').find('button:submit').on('click', function () {
            var form = $(this).closest('form');
            form.save(function () {
                $('a[href$="#categories"]').trigger('click');
            });
            return false;
        });

        $('.ordering-via input:checkbox').on('click', function (e) {
            //if ($('.ordering-via input:checkbox:checked').length == 0) {
            //    return false;
            //}

            var cb = $(this),
                input = cb.closest('.input-group').find('input:not(:checkbox)'),
                group = input.closest('.input-group');

            if (cb.closest('.disabled').length) {
                e.stopPropagation();
                e.preventDefault();
                e.stopImmediatePropagation();
                return false;
            }

            if (!cb.is(':checked')) {
                input.attr('disabled', 'disabled');
                group.removeClass('active-ordering');
            } else {
                input.removeAttr('disabled').focus();
                group.addClass('active-ordering');
            }
        });

        $('.order-time-table input:checkbox').on('click', function () {
            var cb = $(this), group = cb.closest('.wday');

            if (!cb.is(':checked')) {
                group.removeClass('selected');
            } else {
                group.addClass('selected');
            }
        });

        $('.sortable').nestedSortable({
            dropOnEmpty: false,
            containment: 'document',
            items: 'li:not(.new-cat)',
            forcePlaceholderSize: true,
            handle: 'div',
            helper: 'clone',
            forceHelperSize: true,
            opacity: .6,
            placeholder: 'placeholder',
            revert: 250,
            tabSize: 25,
            tolerance: 'pointer',
            toleranceElement: '> div',
            maxLevels: 3,
            isTree: true,
            expandOnHover: 700,
            startCollapsed: false,
            stop: function (e, ui) {
                var nestedArray = $(this)
                    .nestedSortable('toArray', {
                        startDepthCount: 0,
                        excludeRoot: true
                    });

                stringify_tree_to_hidden_input(nestedArray);
                $('#categories').find('form').save(function (data) {
                    create_fresh_category_tree(data);
                    $('.categories-count').children().first().text(nestedArray.length);
                });
            }
        });
        create_fresh_category_tree();

        $('.resort-categories').on('click', function () {
            var rootCatsList = $('.category-tree > ol.sortable'),
                rootListItems = rootCatsList.children('li').get();
            rootListItems.sort(function (a, b) {
                return $(a).find('.category-label').text().toUpperCase()
                    .localeCompare($(b).find('.category-label').text().toUpperCase());
            });
            $.each(rootListItems, function (idx, itm) { rootCatsList.append(itm); });

            $.each(rootListItems, function (idx, itm) {
                var childCatsList = $(itm).find('> ol'),
                    childListItems = childCatsList.children('li').get();
                childListItems.sort(function (a, b) {
                    return $(a).find('.category-label').text().toUpperCase()
                        .localeCompare($(b).find('.category-label').text().toUpperCase());
                });
                $.each(childListItems, function (idx, itm) { childCatsList.append(itm); });
            });

            var nestedArray = $('ol.sortable')
                .nestedSortable('toArray', { startDepthCount: 0, excludeRoot: true });
            stringify_tree_to_hidden_input(nestedArray);
            $('#categories').find('form').save(function (data) {
                create_fresh_category_tree(data);
            });
        });

        $('.btn-expand-category').on('click', function () {
            $(this).closest('li')
                .toggleClass('mjs-nestedSortable-collapsed')
                .toggleClass('mjs-nestedSortable-expanded');
            $(this)
                .toggleClass('glyphicon-chevron-up')
                .toggleClass('glyphicon-chevron-down');
        });

        $('.category-tree .sortable .cat-item').dblclick(function (e) {
            e.stopPropagation();
            e.preventDefault();
            e.stopImmediatePropagation();
            $(this).find('> .cat-div .btn-edit-category').click();
            return false;
        });

        $('.btn-delete-category').click(function () {
            var btn = $(this);
            modal_confirm(btn.attr('data-confirm-delete'), function () {
                btn.closest('.cat-item').remove();

                var nestedArray = $('ol.sortable')
                    .nestedSortable('toArray', {
                        startDepthCount: 0,
                        excludeRoot: true
                    });

                stringify_tree_to_hidden_input(nestedArray);
                $('#categories').find('form').save(function (data) {
                    create_fresh_category_tree(data);
                    $('.categories-count').children().first().text(nestedArray.length);
                });
                $(this).modal('hide');
            });

        });

        $('.btn-edit-category').on('click', function (e) {
            var $this = $(this);
            $this.closest('li').siblings('li').find('.cat-div.edit .cat-name').trigger('blurme');
            $this.closest('.cat-div').addClass('edit').find('.cat-name').focus().select();
        });

        $('input.cat-name')
            .on('keydown', function (e) {
                // enter
                if (e.which == 13) {
                    e.stopPropagation();
                    e.preventDefault();
                    e.stopImmediatePropagation();
                    $(this).trigger('update');
                    return false;
                } else
                    // escape
                    if (e.which == 27) {
                        $(this).trigger('blurme');
                    }
            })
            .on('update', function (e) {
                var input = $(this);
                input.val($.trim(input.val()));

                var label = input.closest('.cat-group').find('.category-label'),
                    newValue = $.trim(input.val() || input.attr('placeholder'));

                label.text(newValue)
                    .toggleClass('empty-cat-name', !$.trim(input.val()));

                if (input.attr('data-value').indexOf('n') !== 0) {
                    $.ajax({
                        url: input.attr('data-url'),
                        type: 'post',
                        data: { name: $.trim(input.val()) },
                        success: function (data) {
                            create_fresh_category_tree(data);
                            input.trigger('blurme');
                        }
                    });
                } else {
                    var nestedArray = $('ol.sortable')
                        .nestedSortable('toArray', { startDepthCount: 0, excludeRoot: true });
                    stringify_tree_to_hidden_input(nestedArray);
                    $('#categories').find('form').save(function (data) {
                        create_fresh_category_tree(data);
                        $('.categories-count').children().first().text(nestedArray.length);
                        input.trigger('blurme');
                    });
                }

                input.closest('.cat-div').removeClass('edit');
            })
            .on('blurme', function (e) {
                $(this).closest('.cat-div').removeClass('edit')
            });

        $('[name=save-cat-name]').on('click', function (e) {
            $(this).closest('.edit-cat-name').find('.cat-name').trigger('update');
            return false;
        });

        $('#categories form').submit(function (e) {
            var nestedArray = $('ol.sortable')
                .nestedSortable('toArray', { startDepthCount: 0, excludeRoot: true });

            stringify_tree_to_hidden_input(nestedArray);

            var form = $(this).closest('form');
            form.save(function (data) {
                create_fresh_category_tree(data);
                $('.categories-count').children().first().text(nestedArray.length);
                $('.nav-tabs a[href$="#products"]').trigger('click');
            });
            return false;
        });

        $('input.new-cat-name')
            .on('keydown', function (e) {
                // enter pressed
                if (e.which === 13) {
                    e.stopPropagation();
                    e.preventDefault();
                    e.stopImmediatePropagation();
                    return false;
                }
            })
            .on('keyup', function (e) {
                var addBtn = $('.category-add');

                if ($(this).val()) {
                    addBtn.removeAttr('disabled');
                    if (e.which === 13) {
                        addBtn.trigger('click');
                    }
                }
                else {
                    addBtn.attr('disabled', 'disabled');
                }
            });

        var newCatId = 1;
        $('.category-add').on('click', function () {
            var catName = $.trim($(this).closest('.input-group').find('input.new-cat-name').val()),
                catId = 'n' + newCatId,
                list = $('.category-tree .sortable');

            var li = $('ol.cat-template li.new-cat').clone(true);
            li.attr('id', 'cat_' + catId);
            li.attr('data-id', catId);
            li.find('.cat-name').attr('name', 'cats[' + catId + '][name]');
            li.find('.cat-name').attr('data-value', catId);
            li.find('.cat-name').val(catName);
            li.find('.category-label').text(catName);
            li.removeClass('new-cat');

            list.prepend(li);
            li.find('.cat-name').trigger('update');
            $('input.new-cat-name').val('');
            $(this).attr('disabled', 'disabled');
            li.slideDown(80);
            newCatId++;
        });

        $('.verify').on('click', function () {
            var input = $(this).closest('.input-div').find('input');

            $.ajax({
                url: $(this).attr('href'),
                type: 'post',
                data: { email: input.val() }
            });

            return false;
        });

        // click on category
        $('body').on('click', '#products .category-label', function () {
            var $this = $(this), tree = $this.closest('.category-tree');
            $.ajax({
                url: tree.attr('data-url'),
                data: {
                    cat_id: $this.attr('data-id')
                },
                dataType: "json",
                beforeSend: function () {
                    $('html').addClass('async-loading');
                    tree.find('.category-label.active').removeClass('active');
                    $this.addClass('active');
                },
                success: function (data) {
                    $('.products-list').html(data.html || '');
                    $('#products_count').text(data.products_count);
                },
            });
            return false;
        });

        // delete all products
        $('.shops-private-product-deleteall').click(function () {
            $(this).async(function (data) {
                $('.products-list').html(data.html);
                $('#products-count').text(0);
            });
            return false;
        });

        // drag/drop product in category
        //$('#products .list-group').draggable({ revert: "valid" });

        $('body').on('shown.bs.modal', '.add-product-modal', function () {
            var productModal = $(this);

            $('.modal-footer input:checkbox').on('click', function () {
                var cb = $(this),
                    url = cb.attr('data-toggle');

                $.ajax({
                    url: url,
                    method: 'post',
                    data: { 'value': cb.prop('checked') },
                    beforeSend: function () {
                        $('html').addClass('async-loading');
                    },
                    success: function (data) {
                        cb.prop('checked', data.status === "true");
                    }
                });
                return false;
            });

            var validate = function (expression, input, formGroup) {
                input.siblings('.glyphicon').remove();

                if (expression) {
                    var ok = $('<span class="glyphicon glyphicon-ok form-control-feedback" aria-hidden="true"></span>');
                    formGroup.removeClass('has-error')
                        .addClass('has-success has-feedback');
                    input.after(ok);
                    input.get(0).defaultValue = input.val();
                }
                else {
                    var warning = $('<span class="glyphicon glyphicon-remove form-control-feedback" aria-hidden="true"></span>');
                    formGroup.removeClass('has-success')
                        .addClass('has-error has-feedback');
                    input.after(warning);
                }
            };

            $('.vld-non-empty').on('blur', function () {
                var input = $(this),
                    formGroup = input.closest('.form-group'),
                    expression = input.val();

                validate(expression, input, formGroup);
            });

            $('.vld-just-numbers-csv').on('blur', function () {
                var input = $(this),
                    formGroup = input.closest('.form-group');

                if (input.val()) {
                    var values = input.val().split(','),
                        isNumber = true;

                    $.each(values, function (i, value) {
                        if (!Number(value)) {
                            isNumber = false;
                            validate(isNumber, input, formGroup);
                            return null;
                        }
                    });

                    validate(isNumber, input, formGroup);
                }
                else {
                    input.siblings('.glyphicon').remove();
                    formGroup.removeClass('has-error has-success has-feedback');
                }
            });

            $('.dropdown-menu-select a').on('click', function () {
                var value = $(this).attr('data-value'),
                    input = $(this).closest('.dropdown-menu-select').find('input:hidden');

                input.val(value);
            });

            // image added to gallery? add it to carousel
            productModal.on('image-added', '.add-photo', function (e, img) {
                var $this = $(this),
                    input = img.find('.img input'),
                    img = img.find('.img img'),
                    item = $('<div class="item"></div>').append(img.clone()).append(input.clone()),
                    carousel = $this.find('.carousel-inner').append(item).closest('.carousel');

                carousel.siblings('.upload-commands').addClass('hidden');

                if (!carousel.find('.item.active').length) {
                    carousel.find('.item:first').addClass('active');
                }

                carousel.trigger('re-init');

                // image removed from gallery? remove from carousel
            }).on('image-removed', '.add-photo', function (e, img) {
                var img = img.find('.img img'), id = img.attr('data-id');
                var $this = $(this);

                var carousel = $this.find('.carousel');
                carousel.find('img[data-id=' + id + ']').closest('.item').remove();

                if (!carousel.find('.item.active').length) {
                    if (!carousel.find('.item:first').addClass('active').length) {
                        carousel.siblings('.upload-commands').removeClass('hidden');
                    }
                }

                carousel.trigger('re-init');

                // re-init carousel
            }).find('.carousel').on('re-init', function () {
                var carousel = $(this);
                carousel.find('.status .current').text(carousel.find('.item.active').index() + 1);
                carousel.find('.status .total').text(carousel.find('.item').length);

                // run on slide
            }).on('slid.bs.carousel', function () {
                $(this).trigger('re-init')

                // run on start
            }).trigger('re-init')

                // click anywhere? open gallery
                .find('.carousel-content').on('click', function () {
                    var $this = $(this), a = $this.closest('.add-photo').find('.upload-commands');
                    var images = $this.find('input[type=hidden]').serialize();

                    if (a.data('href')) {
                        href = a.data('href');
                    } else {
                        href = a.attr('href');
                        a.data('href', href);
                    }
                    a.attr('href', href + '?' + images);
                    a.click();
                });

            productModal.on('click', '.fileupload-img', function (e) {
                var input = $(this),
                    url = input.attr('data-url'),
                    photos_list = $(input.attr('data-photos-container')),
                    limit = input.attr('data-limit'),
                    limit_remove_first = input.attr('data-limit-remove-first'),
                    prepend_photo = input.attr('data-prepend-photo') === 'true';
                // connect_with = input.attr('data-connect-with');

                if (!input.hasClass('fileupload-added')) {
                    input.fileupload({
                        url: input.attr('data-url'),
                        dataType: 'json',
                        sequentialUploads: false,
                        limitConcurrentUploads: 3,
                        done: function (e, data) {
                            if (data.result.imgs) {
                                $.each(data.result.imgs, function (index, imgdata) {
                                    if (data.context) {
                                        var img = data.context.img.find('img')
                                            .attr('src', imgdata.thumb)
                                            .attr('data-id', imgdata.id)
                                            .attr('data-url', imgdata.url);

                                        img.siblings('[type=hidden].id').val(imgdata.id);

                                        // remove url
                                        if ('remove_url' in imgdata) {
                                            data.context.img.find('.btn-remove').attr('data-href', imgdata.remove_url)
                                        }

                                        data.context.img.removeClass('uploading');

                                        // inform connect_with
                                        // if (connect_with) {
                                        //     $(connect_with).trigger('image-added', [ data.context.img ]);
                                        // }

                                        if (limit) {
                                            var photos = photos_list.find('img');
                                            if (photos.length > limit && limit_remove_first) {
                                                if (prepend_photo) {
                                                    photos = $(photos.get().reverse());
                                                }
                                                photos.each(function (i) {
                                                    if (i < limit) {
                                                        $(this).parents('.photo').first().remove();
                                                    }
                                                });
                                            }
                                        }
                                    }
                                });
                            }

                            if (data.result['product_id']) {
                                $('input[name="product-id"]').val(data.result['product_id']);
                            }
                        },
                        submit: function () {
                            return true
                        },
                        progress: function (e, data) {
                            if (data.context) {
                                var progress = parseInt(data.loaded / data.total * 100, 10) + '%';
                                data.context.bar.css('width', progress).text(progress);
                            }
                        },
                        add: function (e, data) {
                            // check limit to disable upload
                            if (limit && !limit_remove_first) {
                                var photos = photos_list.find('.photo');
                                if (photos.length >= limit) {
                                    photos.trigger('limit-reached');
                                    return false;
                                }
                            }

                            var temp_name = '';
                            if (data.files && data.files.length > 0 && data.files[0].name) {
                                temp_name = data.files[0].name;
                            }

                            var newimg = $(Handlebars.compile(photos_list.find('script.tmpl').html())({ uploading: 'uploading', temp_name: temp_name }));
                            var newimg_progress_bar = newimg.find('.progress-bar');
                            if (prepend_photo) {
                                photos_list.prepend(newimg);
                            } else {
                                photos_list.append(newimg);
                            }

                            // check again to show msg rightaway
                            if (limit && !limit_remove_first) {
                                photos = photos_list.find('.photo');
                                if (photos.length >= limit) {
                                    photos.trigger('limit-reached');
                                }
                            }

                            data.context = {
                                img: newimg.first(),
                                bar: newimg_progress_bar.first()
                            };

                            photos_list.removeClass('hidden');
                            photos_list.slideDown();
                            data.submit();
                        },
                        fail: function (e, data) {
                            if (data.context) {
                                data.context.img.remove();
                                //notification(translations.unknown_upload_error);
                                //notification('upload error')
                                var photos = photos_list.find('.photo');
                                if (!photos.length) {
                                    photos.addClass('hidden');
                                } else {
                                    photos.removeClass('hidden');
                                }
                            }
                        },
                        always: function (e, data) {
                            // check again to fix it
                            setTimeout(function () {
                                var photos = photos_list.find('.photo');
                                if (limit && !limit_remove_first) {
                                    if (photos.length >= limit) {
                                        photos.trigger('limit-reached');
                                    } else {
                                        photos.trigger('limit-unreached');
                                    }
                                }
                            }, 100);
                        }
                    }).prop('disabled', !$.support.fileInput)
                        .addClass('fileupload-added')
                        .parent().addClass($.support.fileInput ? undefined : 'disabled')
                }
            });

            productModal.on('limit-reached', function () {
                var uploadBtn = productModal.find('.fileinput-button');
                uploadBtn.siblings('.limit-msg').slideDown();
                uploadBtn.addClass('disabled');
            });

            productModal.on('limit-unreached', function () {
                var uploadBtn = productModal.find('.fileinput-button');
                uploadBtn.siblings('.limit-msg').slideUp();
                uploadBtn.removeClass('disabled');
            });

            productModal.on('click', '.btn-remove[data-href]', function () {
                var btn = $(this);
                btn.async(function () {
                    btn.closest('.photo').slideUp(function () {
                        var photo = btn.closest('.photo');
                        var photos = photo.closest('.photos');

                        $(this).remove();
                        $('.add-photo').trigger('image-removed', [photo]);

                        photos.trigger('limit-unreached');

                        if (!photos.find('.photo').length) {
                            photos.addClass('hidden');
                        }
                    });
                });
            });

            $('.select-categories .cat-div').click(function () {
                var div = $(this),
                    input = div.find('input');

                div.toggleClass("cat-selected");
                input.val() == 0 ? input.val(1) : input.val(0);
            });

            $('.nav-tabs a[href$="#product-labeling"]').on('click', function () {
                var btnSubmit = $('.btn-submit');
                btnSubmit.text(btnSubmit.attr('data-alt-text'));
            });

            $('.nav-tabs a:not([href$="#product-labeling"],[href$="#product-allergens-traces"],[href$="#allergens"],[href$="#traces"],[href$="#product-additives"],[href$="#product-additives"],[href$="#product-nutritions"])').on('click', function () {
                var btnSubmit = $('.btn-submit');
                btnSubmit.text(btnSubmit.attr('data-default-text'));
            });

            var saveTab = function (nextTab) {
                if ($('#product-details').hasClass('active')) {
                    htmlLoading();
                    var form = $('.product-details-form');
                    form.append($('#product-sync').clone().attr('id', null).attr('type', 'hidden'));

                    form.save(function (data) {
                        var new_product = form.closest('.modal').hasClass('new-product-modal');

                        $('input[name="product-id"]').val(data['product_id']);
                        $('.product-photo.add-photo a.async').each(function () {
                            var href = '/shops/' + $('input[name="shop-id"]').val() + '/products/gallery/' + data['product_id'];
                            $(this).attr('href', href);
                        });

                        // product count set in header
                        if ('products_count' in data) {
                            $('#products-count').text(data['products_count']);
                        }

                        if (new_product) {
                            var product_sync = $('#product-sync');
                            if (!product_sync.data('id-added')) {
                                product_sync.attr('data-toggle', product_sync.attr('data-toggle') + '?id=' + data['product_id']);
                                product_sync.data('id-added', true);
                            }
                        }

                        if (nextTab || new_product) {
                            if (!nextTab) {
                                nextTab = $('.nav-tabs a[href$="#product-images"]');
                            }
                            nextTab.closest('li').removeClass('disabled');
                            nextTab.tab('show')
                        } else if (!new_product) {
                            productModal.modal('hide');
                        }
                    });
                } else if ($('#product-images').hasClass('active')) {
                    var new_product = $(this).closest('.modal').hasClass('new-product-modal');
                    if (nextTab || new_product) {
                        if (!nextTab) {
                            nextTab = $('.nav-tabs a[href$="#product-categories"]');
                        }
                        nextTab.closest('li').removeClass('disabled');
                        nextTab.tab('show')
                    } else
                        if (!new_product) {
                            productModal.modal('hide');
                        }
                } else if ($('#product-categories').hasClass('active')) {
                    htmlLoading();
                    $('.product-categories-form').save(function (data) {
                        var new_product = $(this).closest('.modal').hasClass('new-product-modal');
                        if (nextTab || new_product) {
                            if (!nextTab) {
                                nextTab = $('.nav-tabs a[href$="#product-prices"]');
                            }
                            nextTab.closest('li').removeClass('disabled');
                            nextTab.tab('show')
                        } else
                            if (!new_product) {
                                productModal.modal('hide');
                            }
                    });
                } else if ($('#product-prices').hasClass('active')) {
                    htmlLoading();
                    $('.product-prices-form').save(function (data) {
                        var new_product = $(this).closest('.modal').hasClass('new-product-modal');
                        if (nextTab || new_product) {
                            if (!nextTab) {
                                nextTab = $('.nav-tabs a[href$="#product-labeling"]');
                            }
                            nextTab.closest('li').removeClass('disabled');
                            nextTab.tab('show')
                        } else
                            if (!new_product) {
                                productModal.modal('hide');
                            }
                    });
                } else if ($('#product-labeling').hasClass('active')) {
                    htmlLoading();
                    $('.product-labeling-form').save(function (data) {
                        var new_product = $(this).closest('.modal').hasClass('new-product-modal');
                        if (nextTab || new_product) {
                            if (!nextTab) {
                                nextTab = $('.nav-tabs a[href$="#product-certificates"]');
                            }
                            nextTab.closest('li').removeClass('disabled');
                            nextTab.tab('show')
                        } else
                            if (!new_product) {
                                productModal.modal('hide');
                            }
                    });
                } else if ($('#product-certificates').hasClass('active')) {
                    htmlLoading();
                    $('.product-certificates-form').save(function (data) {
                        if (nextTab) {
                            nextTab.closest('li').removeClass('disabled');
                            nextTab.tab('show')
                        } else {
                            productModal.modal('hide');
                        }
                    });
                }
            };

            // save current tab on submit click
            $('.btn-submit').on('click', function () {
                saveTab();
                return false;
            });

            // save current tab on tab change
            $('.add-product-modal .main-modal-tabs [data-toggle=tab]').on('click', function () {
                saveTab($(this));
                return false;
            });

            // certificate on click
            $('body').on('change update', '.certificate-id', function () {
                var logos = $('#product-certificates .certificate-logos');
                var cid = $(this), logo = cid.attr('data-logo');
                logos.find('input[type=hidden][name="' + cid.attr('name') + '"]').remove();

                if (cid.is(':checked')) {
                    logos.append(cid.clone().removeClass('certificate-id').addClass('certificate-id-hidden').attr('type', 'hidden'));

                    // add logo if it is missing
                    if (!logos.find('img[src="' + logo + '"]').length) {
                        var img = $('<img src="" />').attr('src', logo);
                        logos.append(img);
                    }
                } else {
                    // are there any hidden fields with same data-log
                    if (!logos.find('input[type=hidden][data-logo="' + logo + '"]').length) {
                        logos.find('img[src="' + logo + '"]').remove();
                    }
                }

                // show number of selected certificates after the group name
                var cnt = cid.closest('.certificates').find('input[type=checkbox]:checked').length;
                var toggle = $('#certificate-group-toggle');
                var li = toggle.closest('.dropdown').find('.dropdown-menu li a[data-value="' + toggle.siblings('input[type=hidden]').val() + '"]');
                if (cnt) {
                    toggle.find('cnt').removeClass('hidden');
                    li.find('cnt').removeClass('hidden');
                } else {
                    toggle.find('cnt').addClass('hidden');
                    li.find('cnt').addClass('hidden');
                }
                toggle.find('number').text(cnt);
                li.find('number').text(cnt);
            });
            // group changed? load new certificates and load logos if something clicked
            $('body').on('change', '[name=certificate-group-id]', function (e, a) {
                var group = $(this), id = group.val();

                // save selected
                htmlLoading();
                a.closest('form').save(function () {
                    a.async(function (data) {
                        // load new checkboxes
                        if ('certificates' in data) {
                            a.closest('form').find('.certificates').html(data.certificates);
                        }
                    });
                })
                return false;
            });
            $('.certificate-id').first().trigger('update');


            // price calculations
            $('#prices-total-price')
                .on('keyup keydown keypress', function (e) {
                    var total = toNumber($(this).val().trim()),
                        quantity = $('#prices-packaging-quantity').val();
                    price = localizedNumber((total / quantity).toFixed(6));
                    $('#prices-single-price').val(isNumber(price, true) ? toPrice(toNumber(price), 6) : '');
                    $('#prices-vat-eur').trigger('update');
                })
                .on('blur', function (e) {
                    var input = $(this), price = toNumber(input.val().trim()),
                        val = localizedNumber(price.toFixed(4));
                    input.val(isNumber(val, true) ? toPrice(toNumber(val), 4) : '');
                });

            $('#prices-single-price')
                .on('keyup keydown keypress', function (e) {
                    var single = toNumber($(this).val().trim()),
                        quantity = $('#prices-packaging-quantity').val();
                    price = localizedNumber((single * quantity).toFixed(4));
                    $('#prices-total-price').val(isNumber(price, true) ? toPrice(toNumber(price), 4) : '');
                    $('#prices-vat-eur').trigger('update');
                })
                .on('blur', function (e) {
                    var input = $(this), price = toNumber(input.val().trim()),
                        val = localizedNumber(price.toFixed(6));
                    input.val(isNumber(val, true) ? toPrice(toNumber(val), 6) : '');
                });

            $('#prices-beerVat')
                .on('keyup keydown keypress', function (e) {
                    $('#prices-vat-eur').trigger('update');
                })
                .on('blur', function (e) {
                    var input = $(this), price = toNumber(input.val().trim()),
                        val = localizedNumber(price.toFixed(2));
                    input.val(isNumber(val, true) ? val : '');
                    $('#prices-vat-eur').trigger('update');
                });

            $('#prices-packaging-quantity').on('keyup keydown keypress change input', function (e) {
                var total = toNumber($('#prices-total-price').val().trim()),
                    quantity = $(this).val(),
                    price = localizedNumber((total / quantity).toFixed(4));
                $('#prices-single-price').val(isNumber(price, true) ? toPrice(toNumber(price), 4) : '');
            });

            // VAT update
            $('#prices-vat').on('blur focus keyup keydown keypress', function () {
                $('#prices-vat-eur').trigger('update');
            });
            $('#prices-vat-eur').on('update', function () {
                var total = toNumber($('#prices-total-price').val()),
                    beervat = toNumber($('#prices-beerVat').val()),
                    vat = toNumber($('#prices-vat').val());
                $('#prices-vat-eur').val(toPrice(vat && total ? total * vat / 100 : 0, 4));
            }).trigger('update');

            // nutritions
            $('input[name="nutritions[gcal]"]')
                .on('keyup keydown keypress', function (e) {
                    var kcal = toNumber($(this).val().trim());
                    $('input[name="nutritions[gj]"]').val(localizedNumber((kcal * 4.184).toFixed(3)));
                })
                .on('blur', function (e) {
                    var input = $(this),
                        kcal = toNumber(input.val().trim());
                    if (!!kcal) {
                        input.val(localizedNumber(kcal));
                    }
                });

            $('input[name="nutritions[gj]"]')
                .on('keyup keydown keypress', function (e) {
                    var kj = toNumber($(this).val().trim());
                    $('input[name="nutritions[gcal]"]').val(localizedNumber((kj * 0.239006).toFixed(3)));
                })
                .on('blur', function (e) {
                    var input = $(this),
                        value = toNumber(input.val().trim());
                    if (!!value) {
                        input.val(localizedNumber(value));
                    }
                });

            $('.mg-switch').click(function () {
                var mg = $(this).text().trim() === 'mg';
                $('input[name=use_gram]').val(mg ? 1 : 0);

                $('.mg-switch').each(function () {
                    var mgSwitch = $(this);
                    mgSwitch.text(mg ? 'g' : 'mg');
                    var input = mgSwitch.prev('input');
                    if (input.length) {
                        var val = toNumber(input.val().trim());
                        if (val) {
                            val = val * 1;
                            var nval = mg ? val / 1000 : val * 1000;
                            input.val(localizedNumber(nval));
                        }
                    }
                });
                $.ajax({
                    url: '/settings/user/save/single',
                    data: {
                        k: 'user_ingredients_use_gram',
                        v: mg
                    },
                    type: 'post'
                });
            });

            var ga = $('#collapse-gluten-types-allergens'),
                na = $('#collapse-nuts-types-allergens'),
                gt = $('#collapse-gluten-types-traces'),
                nt = $('#collapse-nuts-types-traces');

            // $('.btn-expand-aaa').on('click', function() {
            //     ga.collapse('hide');
            //     na.collapse('hide');
            //     var parent_obj = $(this).parent();
            //     if (parent_obj.attr('aria-expanded')) {
            //         if (parent_obj.attr('data-target') == '#collapse-gluten-types-allergens') {
            //             ga.collapse('show');
            //         } else {
            //             na.collapse('show');
            //         }
            //     }
            // });    

            // $('.btn-expand-aaa-traces').on('click', function() {
            //     gt.collapse('hide');
            //     nt.collapse('hide');
            //     var parent_obj = $(this).parent();
            //     if (parent_obj.attr('aria-expanded')) {
            //         if (parent_obj.attr('data-target') == '#collapse-gluten-types-traces') {
            //             ga.collapse('show');
            //         } else {
            //             na.collapse('show');
            //         }
            //     }
            // });  

            if (ga.find('input:checkbox:checked').length) {
                ga.collapse('show');
                na.collapse('hide');
            }
            if (na.find('input:checkbox:checked').length) {
                na.collapse('show');
                ga.collapse('hide');
            }
            if (gt.find('input:checkbox:checked').length) {
                gt.collapse('show');
                nt.collapse('hide');
            }
            if (nt.find('input:checkbox:checked').length) {
                nt.collapse('show');
                gt.collapse('hide');
            }

            var disableOppositeAllergen = function (oppCb, oppAllergenTypesCbs) {
                oppCb.attr('disabled', 'disabled');
                oppCb.closest('.checkbox').addClass('disabled')
                    .removeAttr('data-toggle')
                    .find('.glyphicon-chevron-down').hide();
                oppAllergenTypesCbs.attr('disabled', 'disabled');
            };

            var enableOppositeAllergen = function (oppCb, oppAllergenTypesCbs) {
                oppCb.removeAttr('disabled');
                oppCb.closest('.checkbox').removeClass('disabled')
                    .attr('data-toggle', 'collapse')
                    .find('.glyphicon-chevron-down').show();
                oppAllergenTypesCbs.removeAttr('disabled');
            };

            $('#product-allergens').find('input:checkbox').on('click', function () {
                var cb = $(this),
                    allergen = cb.attr('data-allergen'),
                    tracesCb = $('#product-allergens-traces').find('input:checkbox[name="allergens[' + allergen + ']"]'),
                    tracesAllergenTypesDiv = $('#collapse-' + allergen + '-types-traces'),
                    tracesAllergenTypesCbs = tracesAllergenTypesDiv.find('input:checkbox');

                if (cb.prop('checked')) {
                    disableOppositeAllergen(tracesCb, tracesAllergenTypesCbs);
                }
                else {
                    enableOppositeAllergen(tracesCb, tracesAllergenTypesCbs);
                    $('#collapse-' + allergen + '-types-allergens')
                        .find('input:checkbox').prop('checked', false);
                }

                tracesAllergenTypesDiv.collapse('hide');
            });

            ga.find('input:checkbox')
                .on('click', function () {
                    var cb = $(this),
                        parentCb = cb.closest('div.collapse')
                            .siblings('.checkbox')
                            .find('input:checkbox'),
                        tracesGlutenCb = $('#traces').find('input:checkbox[name="allergens[gluten]"]'),
                        hasGlutenTypesChecked = cb.closest('#collapse-gluten-types-allergens')
                            .find('input:checkbox:checked').length > 0,
                        tracesGlutenTypesCbs = gt.find('input:checkbox');

                    if (hasGlutenTypesChecked) {
                        parentCb.prop('checked', true);
                        disableOppositeAllergen(tracesGlutenCb, tracesGlutenTypesCbs);
                    }
                    else {
                        parentCb.prop('checked', false);
                        enableOppositeAllergen(tracesGlutenCb, tracesGlutenTypesCbs);
                    }

                    gt.collapse('hide');
                });

            na.find('input:checkbox')
                .on('click', function () {
                    var cb = $(this),
                        parentCb = cb.closest('div.collapse')
                            .siblings('.checkbox')
                            .find('input:checkbox'),
                        tracesNutsCb = $('#traces').find('input:checkbox[name="allergens[nuts]"]'),
                        hasNutsTypesChecked = cb.closest('#collapse-nuts-types-allergens')
                            .find('input:checkbox:checked').length > 0,
                        tracesNutsTypesCbs = nt.find('input:checkbox');

                    if (hasNutsTypesChecked) {
                        parentCb.prop('checked', true);
                        disableOppositeAllergen(tracesNutsCb, tracesNutsTypesCbs);
                    }
                    else {
                        parentCb.prop('checked', false);
                        enableOppositeAllergen(tracesNutsCb, tracesNutsTypesCbs);
                    }

                    nt.collapse('hide');
                });

            $('#traces').find('input:checkbox').on('click', function () {
                var cb = $(this),
                    allergen = cb.attr('data-allergen'),
                    allergenCb = $('#allergens').find('input:checkbox[name="allergens[' + allergen + ']"]'),
                    allergensAllergenTypesDiv = $('#collapse-' + allergen + '-types-allergens'),
                    allergensAllergenTypesCbs = allergensAllergenTypesDiv.find('input:checkbox');

                if (cb.prop('checked')) {
                    disableOppositeAllergen(allergenCb, allergensAllergenTypesCbs);
                }
                else {
                    enableOppositeAllergen(allergenCb, allergensAllergenTypesCbs);
                    $('#collapse-' + allergen + '-types-traces')
                        .find('input:checkbox').prop('checked', false);
                }

                allergensAllergenTypesDiv.collapse('hide');
            });

            gt.find('input:checkbox')
                .on('click', function () {
                    var cb = $(this),
                        parentCb = cb.closest('div.collapse')
                            .siblings('.checkbox')
                            .find('input:checkbox'),
                        allergensGlutenCb = $('#allergens').find('input:checkbox[name="allergens[gluten]"]'),
                        hasGlutenTypesChecked = cb.closest('#collapse-gluten-types-traces')
                            .find('input:checkbox:checked').length > 0,
                        allergensGlutenTypesCbs = ga.find('input:checkbox');

                    if (hasGlutenTypesChecked) {
                        parentCb.prop('checked', true);
                        disableOppositeAllergen(allergensGlutenCb, allergensGlutenTypesCbs);
                    }
                    else {
                        parentCb.prop('checked', false);
                        enableOppositeAllergen(allergensGlutenCb, allergensGlutenTypesCbs);
                    }

                    ga.collapse('hide');
                });

            nt.find('input:checkbox')
                .on('click', function () {
                    var cb = $(this),
                        parentCb = cb.closest('div.collapse')
                            .siblings('.checkbox')
                            .find('input:checkbox'),
                        allergensNutsCb = $('#allergens').find('input:checkbox[name="allergens[nuts]"]'),
                        hasNutsTypesChecked = cb.closest('#collapse-nuts-types-traces')
                            .find('input:checkbox:checked').length > 0,
                        allergensNutsTypesCbs = na.find('input:checkbox');

                    if (hasNutsTypesChecked) {
                        parentCb.prop('checked', true);
                        disableOppositeAllergen(allergensNutsCb, allergensNutsTypesCbs);
                    }
                    else {
                        parentCb.prop('checked', false);
                        enableOppositeAllergen(allergensNutsCb, allergensNutsTypesCbs);
                    }

                    na.collapse('hide');
                });
        });

        // reload last category on modal close
        $('body').on('hidden.bs.modal', '.add-product-modal', function () {
            $('.category-tree .category-label.active').click();
        });

        $('body')
            .on('edit-start', '.add-product-row.add-product-row-view', function (e, focus_element) {
                var view = $(this).addClass('edit-product');

                if (!focus_element || 1 == 1) {
                    focus_element = view.next('.add-product-row').find('.product-name input');
                }
                setTimeout(function () {
                    focus_element.focus().select();
                }, 100);

                view.siblings('.add-product-row.add-product-row-view.edit-product').removeClass('edit-product');
                view.siblings('.new').remove();
            })
            .on('edit-stop', '.add-product-row', function () {
                var row = $(this);
                if (!row.hasClass('add-product-row-view')) {
                    row = row.prev();
                }
                row.removeClass('edit-product');

                var edit = row.next();
                if (edit.data('fast_add_edit')) {
                    edit.replaceWith(edit.data('fast_add_edit'));
                }
            })
            .on('click', '.products-all-delete', function () {
                $.ajax({
                    url: $(this).attr('href'),
                    beforeSend: function () {
                        $('html').addClass('async-loading');
                    },
                    success: function (data) {
                        if ('products_count' in data) {
                            $('#products-count').text(data['products_count']);
                            $('.products-list').empty();
                        }
                    }
                });
                return false;
            })
            .on('click', '.delete-row', function () {
                $(this).closest('.add-product-row').remove();
                return false;
            })
            .on('click', '.add-product-row-view > div', function () {
                var $this = $(this), row = $this.closest('.add-product-row');
                row.trigger('edit-start', [
                    row.prev().find('> div').eq($this.index()).find('input').first()
                ]);

                return false;
            })
            .on('click', '.edit-product-now', function () {
                $(this).closest('.add-product-row').trigger('edit-start');

                return false;
            })
            .on('click', '.product-delete', function () {
                $.ajax({
                    url: $(this).attr('href'),
                    beforeSend: function () {
                        $('html').addClass('async-loading');
                    },
                    success: function (data) {
                        if ('id' in data) {
                            $('[data-id="' + data.id + '"]').fadeOut(function () {
                                $(this).remove()
                            });
                        }
                        if ('products_count' in data) {
                            $('#products-count').text(data.products_count);
                        }
                    }
                });
                return false;
            })
            .on('click', '.product-copy', function () {
                $.ajax({
                    url: $(this).attr('href'),
                    beforeSend: function () {
                        $('html').addClass('async-loading');
                    },
                    success: function (data) {
                        if ('products_count' in data) {
                            $('#products-count').text(data['products_count']);
                        }
                        if ('products_list' in data) {
                            $('.products-list').html(data['products_list']);
                        }
                    }
                });
                return false;
            });

        // download template
        $('input:checkbox[name="products-download"]').on('click', function () {
            var cb = $(this),
                templateBtn = $('.template-download').find('.product-template.btn'),
                href = templateBtn.attr('href');

            if (cb.is(':checked')) {
                templateBtn.attr('href', href + '?' + $.param({ "products-download": true }));
            }
            else {
                templateBtn.attr('href', href.substring(0, href.indexOf('?')));
            }
        });
        $('.get-erroneous-products').on('click', function () {
            var btn = $(this);
            btn.siblings('.upload-error').hide();
        });

        // upload template
        $('.xls-upload .fileupload')
            .on('upload-start', function () {
                $('html').addClass('async-loading');
            })
            .on('upload-success', function (e, data) {
                if (data) {
                    if ('products_count' in data) {
                        $('#products-count').text(data['products_count']);
                    }
                    if ('products_list' in data) {
                        $('.products-list').html(data['products_list']);
                    }
                }
                $('.all-products .category-label').click();
                $('.upload-error').hide();
            })
            .on('upload-error', function (e, data) {
                if (data) {
                    if ('upload_error_message' in data) {
                        $('.upload-error .text-danger').html(data.upload_error_message);
                    }
                    if ('products_list' in data) {
                        $('.products-list').html(data['products_list']);
                    }
                }
            });


        // browse link
        $('.shop .browse').click(function (e) {
            e.stopPropagation();
            e.preventDefault();
            e.stopImmediatePropagation();

            document.location.href = $(this).attr('href');
            return false;
        });

        $('#products').find('form.search').on('submit', function () {
            var form = $(this);
            form.save(function (data) {
                $('.products-list').html(data.html);
            });
            return false;
        });

        // list saved
        $('body').on('shown.bs.modal', '.modal.list-choser-modal', function (e, data) {
            $(this).find('form').data('onSuccess', function (data) {
                var product = $('.product[data-id=' + data.productId + '], .product.productdetails');
                if (product) {
                    var onList = (data.onList == 1);
                    if (onList && 'onThisList' in data) {
                        onList = (data.onThisList == 1);
                    }
                    product.find('.list-product-icon').toggleClass('on-list', onList);

                    if (!onList && product.hasClass('shop-product-list-product')) {
                        product.fadeOut(function () {
                            $(this).remove()
                        });
                    }
                }
            })
        })

        // search
        $('.shops-search form').on('submit', function () {
            var $this = $(this), form = $this.closest('form'),
                search = form.closest('.shops-search'),
                tab = search.closest('.tab-pane');

            if (!$this.is(':visible')) {
                return false;
            }

            clearTimeout(form.data('search-timeout'));
            form.data('search-timeout', setTimeout(function () {
                htmlLoading(true);

                form.save(function (data) {
                    if ('html' in data) {
                        tab.find('> .single, .no-shops-info, #compact-shops-view, #block-shops-view').remove();
                        search.after(data.html);
                    }
                })
            }, 250));

            return false;
        }).find('input[name=q], input[type=hidden]').on('keydown keypress change updated', function () {
            $(this).closest('form').trigger('submit');
        }).trigger('updated')

        // ps: basic form save
        $('#basic-form').on('submit', function () {
            var $this = $(this);

            $this.save(function () {
                $('[data-toggle=tab][href="#products"]').tab('show');
            });

            return false;
        });

        // fast add
        $('body').on('keydown', '#fast-add .add-product-row input', function (e) {
            if (e.which == 13) {
                $(this).trigger('blurme', [1]);
            }
        });
        $('body').on('blur blurme change update', '#fast-add .add-product-row input', function (e, no_timeout) {
            var $this = $(this),
                fast_add = $this.closest('#fast-add'),
                row = $this.closest('.add-product-row'),
                url = row.attr('data-url') || fast_add.attr('data-url');

            var name = row.find('.product-name input');
            if (!$.trim(name.val()).length) {
                if (!row.attr('data-id')) {
                    name.addClass('required').focus();
                    return;
                }
            }
            name.removeClass('required');

            clearTimeout(row.data('update'));
            row.data('update', setTimeout(function () {
                row.find('input').removeClass('changed');
                row.addClass('savingx');
                var params = $.param($this.closest('.add-product-row').find('input').serializeArray());
                //htmlLoading();

                var loading_el = $this;
                if ($this.attr('type') == 'hidden') {
                    loading_el = $this.siblings('.dropdown-toggle').addClass('loading');
                } else {
                    loading_el = $this.addClass('loading');
                }

                $.ajax({
                    url: url,
                    type: 'post',
                    data: params,
                    success: function (data) {
                        var new_prod = ('new' in data && data.new == 1);
                        loading_el.addClass('saved');
                        if (new_prod) {
                            //row.find('.product-name input').addClass('saved');
                        }

                        if ('product_id' in data) {
                            row.removeClass('new');
                            row.attr('data-url', data.url);
                            row.attr('data-id', data.product_id);
                            row.find('a.edit-product').attr('href', data.edit_url);
                            row.find('a.delete-product').attr('href', data.delete_url);
                            row.find('.product-category-names').text(data.category_names);
                            row.find('.new-category-name').attr('data-url', data.category_url);

                            row.find('.product-category-dropdown').toggleClass('no-categories', !data.category_ids.length);

                            // read categories from server
                            //row.find('.dropdown-categories input[type=checkbox]').attr('checked', false);
                            //for (var cat_id in data.category_ids) {
                            //    row.find('input[type=checkbox][data-cat-id="'+data.category_ids[cat_id]+'"]').attr('checked', false);
                            //}
                        } else {
                            var checked = [];
                            row.find('.dropdown-categories input:checked + span').each(function () {
                                checked.push($(this).text())
                            });
                            row.find('.product-category-dropdown').toggleClass('no-categories', !checked.length);

                            if (checked.length) {
                                row.find('.product-category-names').text(checked.join(','));
                            } else {
                                row.find('.product-category-names').text(row.find('.dropdown-categories').attr('data-select-text'));
                            }
                        }

                        if ('fast_add_view' in data) {
                            var fast_add_view = data.fast_add_view;
                            if (new_prod || row.prev().hasClass('edit-product')) {
                                fast_add_view = $(data.fast_add_view).addClass('edit-product');
                            }

                            if (new_prod) {
                                row.before(fast_add_view);
                            } else {
                                row.prev().replaceWith(fast_add_view);
                            }
                        }
                        if ('fast_add_edit' in data) {
                            row.data('fast_add_edit', data.fast_add_edit);
                        }
                    },
                    complete: function () {
                        row.removeClass('saving');
                        loading_el.removeClass('loading');

                        setTimeout(function () {
                            //row.find('.product-name input').removeClass('saved');
                            loading_el.removeClass('saved');
                        }, 1500);

                        var body = $('body');
                        clearTimeout(body.data('focusOutTimeout'));
                        body.data('focusOutTimeout', setTimeout(function () {
                            var focused = $(':focus');
                            var prow = focused.closest('.add-product-row');

                            if (!prow.length || !prow.is(row)) {
                                row.trigger('edit-stop');
                            } else {
                                row.prev('.edit-product').siblings('.edit-product').trigger('edit-stop');
                            }
                        }, 100));
                    }
                });
            }, no_timeout ? 0 : 500));
        }).on('keydown', '#fast-add .add-product-row input', function (e) {
            if (e.which != 13 && e.which != 9) {    // enter
                $(this).addClass('changed')
            }
        });

        $('body').on('ffocus bblur', '*', function () {
            var body = $('body');

            clearTimeout(body.data('focusOutTimeout'));
            body.data('focusOutTimeout', setTimeout(function () {
                var focused = $(':focus');

                if (!focused.closest('.add-product-row').length) {
                    $('.add-product-row-view.edit-product').trigger('edit-stop');
                }
            }, 500));
        });

        // delete ps product
        $('body').on('click', '#fast-add .add-product-row .delete-product', function (e) {
            var $this = $(this), row = $this.closest('.add-product-row');
            $(this).async(function () {
                row.fadeOut(function () {
                    row.siblings('[data-id="' + row.attr('data-id') + '"]').remove();
                    row.remove();
                });
            });
            return false;
        });
        $('body').on('click', '#fast-add .add-product-row input[type=checkbox], #fast-add .add-product-row label', function (e) {
            e.stopPropagation();
            e.stopImmediatePropagation();
            return true;
        });
        $('body').on('click', '#fast-add .add-product-row .show-input', function (e) {
            var $this = $(this);
            $this.closest('li').addClass('input-visible').find('input').focus();
            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();
            return true;

        }).on('keydown', '#fast-add .add-product-row .new-category-name', function (e) {
            var $this = $(this);

            if ((e.which == 13 || e.which == 9) && $.trim($this.val()).length) {
                $.ajax({
                    url: $this.attr('data-url'),
                    type: 'post',
                    data: {
                        name: $this.val()
                    },
                    success: function (data) {
                        var li = $('<li role="presentation"><label><input type="checkbox" value="1" name="categories[]"><span></span></label></li>');
                        li.find('label span').append(data.name);
                        li.find('input').val(data.cat_id).attr('name', "categories[" + data.cat_id + "]");
                        $this.val('');

                        // add everywhere
                        $this.closest('#fast-add').find('.add-product-row .input-in-dropdown').after(li);

                        li = $this.closest('li').removeClass('input-visible');
                        setTimeout(function () {
                            li.find('.show-input').focus();
                        }, 100);

                        li.next('li').find('[type=checkbox]').attr('checked', true);

                        var filter = $('<li role="presentation"><a href=""></li>');
                        filter.find('a').attr('href', data.filter_url).attr('data-value', data.filter_url).text(data.name);
                        $('.category-filter-dd .dropdown-menu li').first().after(filter);
                    }
                });

            }
        });

        $('body').on('click', '#fast-add .open-categories', function (e) {
            $('[data-toggle=tab][href="#categories"]').tab('show')
            e.stopPropagation();
            e.stopImmediatePropagation();
            return false;
        });

        $('body').on('click', '.fast-add-more', function (e) {
            var $this = $(this),
                fast_add = $this.closest('#fast-add'),
                row = fast_add.find('.add-product-row.new-line').clone(true, true);

            if ($this.hasClass('prepend')) {
                fast_add.find('.add-product-head').first().after(row.removeClass('new-line'));
            } else {
                fast_add.find('.add-product-row').last().after(row.removeClass('new-line'));
            }

            row.find('.product-name input').focus();
            row.siblings('.edit-product').trigger('edit-stop');
            return false;
        });
        $('body').on('show.bs.dropdown', '#fast-add .category > .dropdown', function (e, x) {
            var $this = $(this);

            if ($this.find('li').length <= 2) {
                var input = $this.find('li.input-in-dropdown').addClass('input-visible').find('.new-category-name').focus();
                setTimeout(function () {
                    input.focus();
                }, 100)
            }
        });

        // mark empty dropdown
        $('body').on('change', '.add-product-row .dropdown-toggle + input[type=hidden]', function (e) {
            var $this = $(this);
            $this.siblings('.dropdown-toggle').toggleClass('no-packaging', !$.trim($this.val()).length);
        });



        // remove products from products list and show loading indicator
        function newFilterFastAddProductsInit() {
            htmlLoading();
            $('.products-list').html('');
            $('#products_count').text(0);
            $('.products-list').addClass('scroll-loading');
        }

        // remove loading indicator; populate products list and update scroll URL for loading next batch of products
        function newFilterFastAddProductsCompleted(data) {
            $('.products-list').removeClass('scroll-loaded');
            $('.products-list').removeClass('scroll-loading');
            $('.products-list').removeAttr('data-scroll-load-url-old');
            $('.products-list').attr('data-scroll-load-url', data.url || '');

            $('.products-list').html(data.html || '');
            $('#products_count').text(data.products_count);
        }

        // filter by category and search param on category change
        $('[name=category-filter-url]').on('change', function () {
            newFilterFastAddProductsInit();

            var qq = $('input[name=qq]').val();
            var $url = $(this).val();
            $url += (qq === '' ?
                '' :
                (($url.includes('?') ? '&' : '?') + 'qq=' + qq)
            );

            $.ajax({
                url: $url,
                success: function (data) {
                    newFilterFastAddProductsCompleted(data);
                },
            });
        });

        // filter by search param and category on deleted or submitted input
        $('input[name=qq]').keyup(function (e) {
            if (e.keyCode == 13 || (e.keyCode == 8 && $(this).val() === '')) {
                e.stopPropagation();
                e.preventDefault();
                e.stopImmediatePropagation();

                newFilterFastAddProductsInit();

                var cat_url = $('[name=category-filter-url]').val();
                var form_url = $(this).closest('form.search')?.attr('action');
                var $url = cat_url || form_url;

                if ($url) {
                    $url += $url.includes('?') ? '&' : '?';
                    $url += 'qq=' + $(this).val();

                    $.ajax({
                        url: $url,
                        success: function (data) {
                            newFilterFastAddProductsCompleted(data);
                        },
                    });
                }
            }
        });



        var show_products_tab = function () {
            $('a[data-toggle="tab"][href="#products-quick-add"]').tab('show');
        };
        // open first subtab
        $('a[data-toggle="tab"][href="#products"]').on('shown.bs.tab, click', function (e) {
            show_products_tab();
        })

        $('body').on('loaded.full loaded.part', '.products-list', function () {
            $(this).find('.help[title]').tooltip();
        });

        $('body').on('updated', '.products-list .packaging-unit[type=hidden]', function () {
            var $this = $(this),
                packaging_row = $this.closest('.add-product-row').find('.large-packaging'),
                packaging = packaging_row.find('input[type=hidden]'),
                packaging_select = packaging_row.find('a[data-value="' + $this.val() + '"]');

            if (!packaging.val() && packaging_select.length) {
                packaging_select.trigger('clicked');
            }
        });

        // shop product list resort
        function shopProductListResort() {
            $('#productBlocks').sortable({
                items: '> .shop-product-list-product',
                scroll: true,
                containment: 'parent',
                //placeholder: "shop-product-list-product-placeholder",
                sort: function (e, ui) {
                    ui.helper.css({ 'top': ui.position.top + $(window).scrollTop() + 'px' });
                },
                update: function (e, ui) {
                    var $this = $(this);

                    var products = [];
                    $this.find('> .shop-product-list-product').each(function () {
                        products.push($(this).attr('data-id'))
                    })

                    $.ajax({
                        url: $this.attr('data-resort-url'),
                        type: 'post',
                        data: {
                            order: products
                        },
                    });
                }
            });
        }

        shopProductListResort();

        $('body').on('click', '.productBlock .packagings-selector', function (e) {
            return false;
        });

        $('body').on('click', '.productBlock .packagings-selector li', function (e) {
            e.stopPropagation();
            e.preventDefault();
            e.stopImmediatePropagation();

            var $this = $(this);

            $this.addClass('active').siblings().removeClass('active');
            var product = $this.closest('.product');

            product.find('.packagings .pack-quantity').text($this.find('.pack-quantity').attr('data-short'));
            product.find('.packagings .pack-name').text($this.find('.pack-name').text());
            product.find('.packagings .pack-price').text($this.find('.pack-price').text());

            product.find('[name=packaging]').val($this.attr('data-id'));

            if (product.hasClass('in-cart')) {
                product.find('[name=quantity]').trigger('change');
            }
            return false;
        });

        $('body').on('click', '.productBlock .packagings-selector .save-selection', function (e) {
            var $this = $(this);

            $.ajax({
                url: $this.attr('href'),
                data: {
                    packaging: $this.closest('.product').find('[name=packaging]').val()
                },
                type: 'post',
                success: function () {
                    $this.closest('.product').find('.packagings-selector').hide();
                }
            })

            return false;
        });

        $('body').on('click', '.productBlock .packagings-selector .cancel', function (e) {
            $(this).closest('.product').find('.packagings-selector').hide();
            return false;
        });

        $('body').on('click', '.packagings .selector', function (e) {
            var product = $(this).closest('.product');
            product.siblings().find('.packagings-selector').hide();
            product.find('.packagings-selector').toggle();
            return false;
        });

        // close packaging selector
        $(document).click(function (e) {
            if (!$(e.target).closest('.packagings-selector').length) {
                $('.packagings-selector').hide();
            }
        });
        $('body').on('keypress keyup keydown', function (e) {
            if (e.which == 27) {
                $('.packagings-selector').hide();
            }
        });
        // hide restaurant checkbox list if shop products list will be private
        $('#public-list, #whitelist').on('change', function (e) {
            if ($('#public-list').is(':checked') || $('#whitelist').is(':checked')) {
                $('#restaurant-selector.list-block').removeClass('list-item-display');
            } else {
                $('#restaurant-selector.list-block').addClass('list-item-display');
            }
        });
        $('#public-list').trigger('change');

        // disable save button if none of checkbox lists are checked
        $('.list-block input[type=checkbox]').click(function () {
            var anyBoxesChecked = false;
            $('.list-block input[type=checkbox]').each(function () {
                if ($(this).prop('checked')) {
                    anyBoxesChecked = true;
                    $('button[type=submit]').prop('disabled', false);
                }
            });
            if (anyBoxesChecked == false) {
                $('button[type=submit]').prop('disabled', true);
            }
        });


        // set message for user after activating "orderable products"
        $('#use-whitelists').click(function () {
            console.log("xox");
            $('#checkbox-notify').toggleClass('hidden', !$('#use-whitelists').is(':checked'));
        });
        /*
            $('.cost-center-switch').click(function() {
                var url = document.location.href.split("?")[0] + "?costCenterId=" + $(this).attr('data-cost-center-id');
                document.location.href = url;
            });
         */

    });
//set message for admin after selecting a override subject
$('input[name=override-subject-checkbox]').change(function () {
    if (!$(this).is(':checked')) {
        $('#order-via-email-subject').prop("required", false);

    } else {
        $('#order-via-email-subject').prop("required", true);
    }
});

$('.order-notes-group-block').on('mouseover', function () {
    $(this).find('.action-icons').css('visibility', 'visible');
});

$('.order-notes-group-block').on('mouseout', function () {
    $(this).find('.action-icons').css('visibility', 'hidden');
});

$('.order-notes-edit-icon').on('click', function () {
    let parent = $(this).parents('.order-notes-group-block');
    parent.addClass('hidden');
    parent.next().removeClass('hidden');
    parent.next().find('input').val(parent.find('p').html());
});

$('.order-notes-exit-icon').on('click', function () {
    let parent = $(this).parents('.order-notes-group-block-editable');
    parent.addClass('hidden');
    parent.prev().removeClass('hidden');
});

$('.order-notes-confirm-icon').on('click', function () {
    let parent = $(this).parents('.order-notes-group-block-editable');
    let noteId = parent.attr('data-id');
    $.ajax({
        url: '/shop/' + noteId + '/order-notes-edit',
        data: {
            note: parent.find('.edit-input').val()
        },
        type: 'post',
        success: function (data) {
            parent.addClass('hidden');
            parent.prev().removeClass('hidden');
            if (data['note'].trim() != "") {
                parent.find('input').val(data['note']);
                parent.prev().find('.text').html(data['note']);
            }
        }
    });
});

$('.order-notes-remove-icon').on('click', function () {
    let parent = $(this).parents('.order-notes-group-block');
    let noteId = parent.attr('data-id');
    $.ajax({
        url: '/shop/' + noteId + '/order-notes-remove',
        type: 'post',
        success: function (data) {
            let group = parent.parents('.order-notes-group');
            group.parents('.row').prev().remove();
            group.parents('.row').remove();
        }
    });
});

$('body').on('keyup', '.edit-input', function (e) {
    if (e.which == 13) {
        $('.order-notes-confirm-icon').trigger('click');
    } else if (e.which == 27) {
        $('.order-notes-exit-icon').trigger('click');
    }
});





